@import '../../styles/fonts';
@import '../../styles/colors';

.slotOverlay
{
    position:    absolute;
    top:         0;
    left:        0;
    width:       100%;
    height:      100%;
    background:  $colorWhite70;
    text-align:  center;
    display:     flex;
    align-items: center;
}

.slotOverlayTextCenter
{
    justify-content: center;
    text-align:      center;
}

.slotOverlayText
{
    font-family:     $mainFont;
    font-size:       18px;
    color:           $colorGrayLight;
    font-weight:     bold;
    white-space:     nowrap;
    overflow:        hidden;
    text-overflow:   ellipsis;
    text-shadow:     0 0 27px $colorWhite;
    padding:         0 15px;
    box-sizing:      border-box;
    text-align:      left;
    justify-content: left;
}

.slotOverlayTextLeft
{

}

.slotOverlayTextRight
{
    display:         flex;
    position:        absolute;
    top:             0;
    right:           0;
    text-align:      right;
    align-items:     center;
    justify-content: flex-end;
    height:          100%;
    width:           100%;
}
