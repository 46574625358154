@import '../../styles/fonts';
@import '../../styles/colors';

.productBrowserManager
{
    width:    100%;
    height:   100%;
    display:  block;
    position: relative;
}

.productBrowserManagerActiveProductCategoryIndicator
{
    color:       $colorGrayLighter;
    font-family: $mainFont;
    font-size:   11px;
    position:    absolute;
    top:         10px;
    right:       15px
}
