@import '../../styles/fonts';
@import '../../styles/colors';

.subSlotListWrapper
{
    position: relative;
    margin:   0 0 25px 0;
}

.subSlotList
{
    box-shadow: 0 1px 2px 0 $colorGrayLighter;
}

.subSlotListTitle
{
    color:          $colorGrayLighter;
    font-size:      13px;
    text-transform: uppercase;
    font-family:    $mainFont;
    margin:         0 0 6px 0;
}
