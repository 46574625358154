//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

$colorPrimary:                #ef7d00;
$colorPrimaryDarker:          #cb5c00;
$colorPrimaryLighter:         #ffb459;
$colorPrimaryLighter2:        #ffe4c9;
$colorPrimaryLighter3:        #fff2e3;
$colorHeaderStart:            #ef7d00;
$colorHeaderEnd:              #ffb459;
$colorBannerBackground:       #ecccca;
$colorBannerText:             #000000;
$colorHeaderButtonBackground: #505455;
$colorHeaderButtonBorder:     #505455;
$colorHeaderButtonText:       #a8adae;
$colorHeaderButtonIcon:       #dee0e0;
$colorBigLogoText:            #ffffff;
