@import '../../styles/fonts';
@import '../../styles/colors';
@import '../../styles/variables';
@import '../../styles/zIndexes';

.productSlotListSlot
{
    border-bottom: 1px solid $colorGrayLighter3;
    position:      relative;
    transition:    background-color $defaultTransitionSpeed linear;
    user-select:   none;

    &:last-child
    {
        border-bottom: 0;
    }

    &.productSlotListSlotWithItem
    {
        &:hover
        {
            background: $colorPrimaryLighter2;
        }
    }

    &:nth-child(even)
    {
        background:        $colorWhite;
        border-left-color: $colorWhite;
    }

    &:nth-child(odd)
    {
        background:        $colorGrayLighter2;
        border-left-color: $colorGrayLighter2;
    }
}

.productSlotListSlotWithItem
{
    cursor: pointer;
}

.productSlotListSlotSelected
{

}

.productSlotListSlotWrapper
{
    border:     1px solid $colorTransparent;
    transition: border-color $defaultTransitionSpeed linear;
    position:   relative;
}

.productSlotListSlotWrapperWithWarning
{
    border-color: $colorPrimary;
}

.productSlotDeleteButton,
.productSlotLock
{
    background-size: contain;
    color:           $colorGrayLight;
    font-size:       11px;
    font-family:     $mainFont;
    display:         block;
    position:        absolute;
    top:             6px;
    right:           8px;
    width:           14px;
    overflow:        hidden;
    z-index:         $zIndexSlotDeleteButton;

    &.productSlotDeleteButton
    {
        cursor: pointer;
    }

    &.productSlotLock
    {
        cursor: not-allowed;
    }

    svg
    {
        *
        {
            fill: $colorGrayLight;
        }
    }

    &:hover
    {
        svg
        {
            *
            {
                fill: $colorGray;
            }
        }
    }

    &:active
    {
        svg
        {
            *
            {
                fill: $colorPrimary;
            }
        }
    }
}

.productSlotListSlotTypeAccessory
{
    background: $colorGreenLight !important;

    &.productSlotListSlotWithItem
    {
        &:hover
        {
            background: $colorGreen !important;

            .productSlotListSlotTitleWithCustomText,
            .productSlotListSlotTitle,
            .productSlotListSlotTitleEmpty,
            .productSlotListSlotTitleSub,
            .productSlotListSlotNumber
            {
                color: $colorWhite !important;
            }
        }
    }

    .productSlotListSlotContent
    {
        border-left-color: $colorGreen
    }
}

.productSlotListSlotTypePowerSupplyUnit
{
    background: $colorPurpleLight !important;

    &.productSlotListSlotWithItem
    {
        &:hover
        {
            background: $colorPurple !important;

            .productSlotListSlotTitleWithCustomText,
            .productSlotListSlotTitle,
            .productSlotListSlotTitleEmpty,
            .productSlotListSlotTitleSub,
            .productSlotListSlotNumber
            {
                color: $colorWhite !important;
            }
        }

        &:hover
        {
            svg
            {
                *
                {
                    fill: $colorWhite80;
                }

                &:hover
                {
                    *
                    {
                        fill: $colorWhite;
                    }
                }
            }
        }

        &:active
        {
            svg
            {
                *
                {
                    fill: $colorWhite;
                }
            }
        }
    }

    .productSlotListSlotContent
    {
        border-left-color: $colorPurple;
    }
}

.productSlotListSlotSelected
{
    background: $colorPrimaryLighter2 !important;

    &:hover
    {
        .productSlotListSlotContent
        {
            border-left-color: $colorPrimary !important;

            .productSlotListSlotTitleWithCustomText,
            .productSlotListSlotTitle,
            .productSlotListSlotTitleEmpty,
            .productSlotListSlotTitleSub,
            .productSlotListSlotNumber
            {
                color: $colorGrayLight !important;
            }
        }

        svg
        {
            *
            {
                fill: $colorGrayLight !important;
            }
        }
    }
}

.productSlotListSlotTypeDefault
{

}

.productSlotListSlotTypeCpu
{
    background: $colorYellowLight !important;

    &.productSlotListSlotWithItem
    {
        &:hover
        {
            background: $colorYellow !important;

            .productSlotListSlotTitleWithCustomText,
            .productSlotListSlotTitle,
            .productSlotListSlotTitleEmpty,
            .productSlotListSlotTitleSub,
            .productSlotListSlotNumber
            {
                color: $colorWhite !important;
            }
        }
    }

    .productSlotListSlotContent
    {
        border-left-color: $colorYellow;
    }
}

.productSlotListSlotTypeFan
{
    background: $colorBlueLight !important;

    &.productSlotListSlotWithItem
    {
        &:hover
        {
            background: $colorBlue !important;

            .productSlotListSlotTitleWithCustomText,
            .productSlotListSlotTitle,
            .productSlotListSlotTitleEmpty,
            .productSlotListSlotTitleSub,
            .productSlotListSlotNumber
            {
                color: $colorWhite !important;
            }
        }
    }

    .productSlotListSlotContent
    {
        border-left-color: $colorBlue;
    }
}

.productSlotListSlotContent
{
    display:        flex;
    flex-direction: row;
    padding:        6px;
    border-left:    2px solid $colorTransparent;
}

.productSlotListSlotNumber
{
    color:       $colorGrayLight;
    font-size:   14px;
    font-family: $mainFont;
    min-width:   26px;
    transition:  color $defaultTransitionSpeed linear;
    white-space: pre;
}

.productSlotListSlotErrorContainer
{
    list-style: none;
}

.productSlotListSlotErrorContainerItem
{
    background:  $colorPrimary;
    color:       $colorWhite;
    font-size:   14px;
    font-family: $mainFont;
    padding:     4px 11px;
}

.productSlotListSlotTitle
{
    color:         $colorGrayLight;
    font-size:     14px;
    font-family:   $mainFont;
    font-weight:   600;
    width:         100%;
    white-space:   nowrap;
    height:        20px;
    overflow:      hidden;
    text-overflow: ellipsis;
    transition:    color $defaultTransitionSpeed linear;
}

.productSlotListSlotTitleWithCustomText
{
    color: $colorPrimary;
}

.productSlotListSlotTitleEmpty
{
    color:       $colorGrayLighter;
    font-size:   14px;
    font-family: $mainFont;
}

.productSlotListSlotExtenderId
{
    font-family:   $mainFont;
    font-size:     14px;
    font-weight:   300;
    color:         $colorGrayLight;
    transition:    color $defaultTransitionSpeed linear;
    max-width:     50%;
    text-overflow: ellipsis;
    overflow:      hidden;
    white-space:   nowrap;
}

.productSlotListSlotTitleSub
{
    font-family: $mainFont;
    font-size:   14px;
    font-weight: 300;
    color:       $colorGrayLight;
    transition:  color $defaultTransitionSpeed linear;
}

.productSlotListSlotTitleSubWrapper
{
    display:         flex;
    justify-content: space-between;
}

.productSlotListSlotTitleWrapper
{
    padding:        0;
    display:        flex;
    flex-direction: column;
    box-sizing:     border-box;
    width:          100%;
}

.productSlotListSlotTitleWrapperHasTitle
{
    padding-right: 50px;
}

.productSlotListSlotDefaultSlotDropZoneWrapper
{
    position:       absolute;
    top:            0;
    left:           0;
    height:         100%;
    width:          100%;
    pointer-events: none;
}

.productSlotListSlotDefaultSlotDropZoneWrapperDoubleSlot
{
    height: 50%;
}

.productSlotListSlotDoubleSlotDropZoneWrapper
{
    position: absolute;
    left:     0;
    height:   50%;
    width:    100%;
}

.productSlotListSlotDoubleSlotDropZoneWrapperTop
{
    top: 0;
}

.productSlotListSlotDoubleSlotDropZoneWrapperBottom
{
    height: 46.4%; // This fixes some minor design flaws
    bottom: 0;
}
