@import '../../styles/fonts';
@import '../../styles/colors';

.subLevelTabList
{
    height:          26px;
    background:      $colorGrayLighter2;
    display:         flex;
    flex-direction:  row;
    list-style:      none;
    text-align:      center;
    justify-content: space-around;

}
