@import '../../styles/fonts';
@import '../../styles/colors';

.groupSimilarProductSwitch
{
    display:       inline-flex;
    margin-left:   25px;
    padding-right: 25px;
}

@media only screen and (max-width: 1280px)
{
    .groupSimilarProductSwitch
    {
        display: none;
    }
}

.groupSimilarProductSwitchLeft,
.groupSimilarProductSwitchRight
{
    display:     flex;
    align-items: center;
}

.groupSimilarProductSwitchLeft
{
    flex:        0 auto;
    margin-left: 5px;
}

.groupSimilarProductSwitchLeftText
{
    color:       $colorGrayLight;
    font-size:   14px;
    font-family: $mainFont;
    font-weight: normal;
    margin:      0 5px 0 0;

    &.groupSimilarProductSwitchLeftNoText
    {
        justify-content: flex-start;
    }
}

.groupSimilarProductSwitchRight
{
    flex:            0 auto;
    align-items:     flex-start;
    flex-direction:  column;
    text-align:      center;
    justify-content: center;
    margin:          auto auto auto 20px;

    &.groupSimilarProductSwitchRightNoText
    {
        justify-content: flex-start;
    }
}
