@import '../../styles/fonts';
@import '../../styles/colors';

.rightPaneRouter
{
    display:        flex;
    flex-direction: column;
    height:         100%;
}

.rightPaneRouterActions
{
}

.rightPaneRouterContent
{
    padding:   30px 30px 0 30px;
    flex-grow: 1;

    &::-webkit-scrollbar
    {
        width: 0 !important;
    }

    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px)
    {
        padding: 15px 15px 0 15px;
    }
}
