@import '../../styles/fonts';
@import '../../styles/colors';
@import '../../styles/variables';

.segmentControl
{
    display:       flex;
    border-radius: 3px;
    height:        30px;
    overflow:      hidden;
    font-size:     14px;

    &.default
    {
        background: $colorGrayLighter2;
    }

    &.darkBackgroundSmallText
    {
        background:     $colorGrayLighter3;
        font-size:      14px;
        letter-spacing: 2px;
        height:         24px;
    }
}

.segmentControlSegment
{
    border-left:     1px solid $colorGrayLighter3;
    display:         flex;
    justify-content: center;
    align-items:     center;
    transition:      background-color $defaultTransitionSpeed linear,
                     color $defaultTransitionSpeed linear;
    font-family:     $mainFont;
    text-align:      center;
    padding:         0 14px;
    cursor:          pointer;
    user-select:     none;

    &:first-child
    {
        border-left: 0;
    }

    &:hover,
    &.segmentControlSegmentSelected
    {
        background: $colorPrimary;
        color:      $colorWhite;
    }

    &.darkBackgroundSmallText
    {
        padding: 0 6px;
    }
}
