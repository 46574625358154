@import '../../styles/fonts';
@import '../../styles/colors';

.salesMailboxForm
{
    display:         flex;
    flex-wrap:       wrap;
    justify-content: flex-end;
    margin:          0 -20px 0 0;

    > div
    {
        flex:         40%;
        margin-right: 20px;
    }
}

.salesMailboxBottomPanel
{
    background: $colorGrayLighter2;
    margin:     0 -27px -27px -27px;
    padding:    27px;
}
