//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/fonts';
@import '../../styles/colors';
@import '../../styles/variables';

.leftPaneLinkButton
{
    position:        relative;
    height:          70px;
    display:         flex;
    text-decoration: none;
    align-items:     center;
    justify-content: flex-start;
    text-transform:  uppercase;
    font-weight:     600;
    padding:         0 0 0 $leftPaneSidePadding;
    font-size:       13px;
    border-bottom:   1px solid $colorGrayLighter3;
    transition:      background-color $defaultTransitionSpeed linear;

    &:hover
    {
        background-color: $colorPrimaryLighter2;

        .leftPaneLinkButtonIcon
        {
            animation-name:            leftPaneLinkButtonIconLeftRight;
            animation-duration:        2s;
            animation-iteration-count: infinite;

            svg
            {
                *
                {
                    fill: $colorPrimary;
                }
            }
        }
    }
}

.leftPaneLinkButtonActive
{
    background-color: $colorPrimaryLighter2;

    .leftPaneLinkButtonIcon
    {
        transform: translateX(4px);

        svg
        {
            *
            {
                fill: $colorPrimary;
            }
        }
    }
}

.leftPaneLinkButtonBadge
{
    background:    $colorPrimary;
    height:        20px;
    text-align:    center;
    min-width:     8px;
    position:      absolute;
    color:         $colorWhite;
    font-size:     13px;
    right:         55px;
    border-radius: 10px;
    font-family:   $mainFont;
    padding:       0 6px;
    line-height:   19px;
    top:           50%;
    margin-top:    -10px;
}

.leftPaneLinkButtonIcon
{
    $leftPaneLinkButtonIconSize: 24px;

    position:                    absolute;
    right:                       21px;
    top:                         50%;
    margin:                      (-1 * (calc($leftPaneLinkButtonIconSize / 2))) 0 0 0;
    display:                     block;
    width:                       $leftPaneLinkButtonIconSize;
    height:                      $leftPaneLinkButtonIconSize;
    transition:                  transform $defaultTransitionSpeed linear;

    svg
    {
        *
        {
            fill: $colorGray;
        }
    }
}

.leftPaneLinkButtonText
{
    color:         $colorGrayLighter;
    font-family:   $mainFont;
    font-size:     13px;
    margin-right:  88px;
    margin-bottom: -1px;
    line-height:   16px;
}

@keyframes leftPaneLinkButtonIconLeftRight
{
    50%
    {
        transform: translateX(4px);
    }
}
