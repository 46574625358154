@import '../../styles/fonts';
@import '../../styles/colors';
@import '../../styles/zIndexes';

.tooltip
{
    cursor: help;
}

:global .tooltipWrapper
{
    padding:         0;
    display:         flex;
    justify-content: center;
    align-items:     center;

    svg
    {
        height: 19px;
    }
}

:global .tooltipWrapperGray
{
    svg
    {
        *
        {
            fill: $colorGrayLighter;
        }
    }

    &:hover
    {
        svg
        {
            *
            {
                fill: $colorGray;
            }
        }
    }
}

:global .tooltipWrapperOrange
{
    svg
    {
        *
        {
            fill: $colorPrimary;
        }
    }

    &:hover
    {
        svg
        {
            *
            {
                fill: $colorPrimaryDarker;
            }
        }
    }
}

:global .tooltipWrapperWhite
{
    svg
    {
        *
        {
            fill: $colorWhite;
        }
    }

    &:hover
    {
        svg
        {
            *
            {
                fill: $colorGray;
            }
        }
    }
}

$tooltipContentPadding: 25px;

:global .tooltipContentWrapper
{
    border-radius: 4px;

    h5,
    p
    {
        overflow:    hidden;
        color:       $colorWhite !important;
        font-family: $mainFont;
        font-size:   14px;
    }

    h5
    {
        height:        50px;
        font-size:     14px;
        font-weight:   600;
        line-height:   50px;
        padding:       0 $tooltipContentPadding 0 $tooltipContentPadding;
        border-radius: 4px 4px 0 0 !important;
    }

    p
    {
        background:    $colorGrayLight3;
        padding:       14px $tooltipContentPadding;
        font-weight:   normal;
        border-radius: 0 0 4px 4px !important;
    }

}

:global .tooltipStyle
{
    padding:          0 !important;
    opacity:          1 !important;
    background-color: $colorGrayLight2 !important;
    max-width:        400px;
    z-index:          $zIndexTooltip !important;
}

:global .__react_component_tooltip.place-left::after
{
    border-left: 8px solid $colorGrayLight3 !important;
}

:global .__react_component_tooltip.place-right::after
{
    border-right: 8px solid $colorGrayLight3 !important;
}

:global .__react_component_tooltip.place-top::after
{
    border-top: 8px solid $colorGrayLight3 !important;
}

:global .__react_component_tooltip.top-right::after
{
    right:  10px !important;
    margin: 0 !important;
    bottom: -8px !important;
    left:   auto !important;
}

:global .__react_component_tooltip.bottom-right::after
{
    right:  10px !important;
    margin: 0 !important;
    bottom: -8px !important;
    left:   auto !important;
}

:global .__react_component_tooltip.place-bottom::after
{
    border-bottom: 8px solid $colorGrayLight2 !important;
}
