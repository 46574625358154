@import '../../styles/fonts';
@import '../../styles/colors';

.selectedProductImageRenderer
{
    position: absolute;
    cursor:   default;
}

.selectedProductImageRendererClickable
{
    cursor: pointer;
}

.selectedProductImageRendererAccessory
{
    position:       absolute;
    top:            0;
    left:           0;
    width:          100%;
    height:         100%;
    pointer-events: none;
}
