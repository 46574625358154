@import '../../styles/fonts';
@import '../../styles/colors';

.slotSfpListWrapper
{
    position: relative;
    margin:   0 0 25px 0;
}

.slotSfpList
{
    box-shadow: 0 1px 2px 0 $colorGrayLighter;
}

.slotSfpListTitle
{
    color:          $colorGrayLighter;
    font-size:      13px;
    text-transform: uppercase;
    font-family:    $mainFont;
    margin:         0 0 6px 0;
}
