@import '../../styles/fonts';
@import '../../styles/colors';
@import '../../styles/variables';

.mainLevelTabButton
{
    padding:     0 14px;
    height:      25px;
    border-top:  2px solid $colorWhite;
    color:       $colorGrayLight;
    font-family: $mainFont;
    line-height: 22px;
    user-select: none;
    cursor:      pointer;
    transition:  border-top-color $defaultTransitionSpeed linear,
                 background-color $defaultTransitionSpeed linear,
                 color $defaultTransitionSpeed linear;

    &:hover
    {
        color: $colorPrimary;
    }
}

.mainLevelTabButtonDisabled
{
    color:  $colorGrayLighter !important;
    cursor: not-allowed;
}

.mainLevelTabButtonSelected
{
    color:            $colorPrimary;
    background:       $colorGrayLighter2;
    border-top-color: $colorPrimary;

    .mainLevelTabButtonText
    {
        font-weight: 600;
    }
}

.mainLevelTabButtonText
{
    font-weight: normal;
    font-size:   14px;
}
