@import '../../styles/fonts';
@import '../../styles/colors';
@import '../../styles/variables';

.leftPaneBackButton
{
    background-size: contain;
    color:           $colorGrayLight;
    font-size:       11px;
    font-family:     $mainFont;
    cursor:          pointer;
    margin:          0 0 22px 0;
    text-decoration: none;
    display:         flex;
    flex-direction:  row;
    align-items:     center;

    &:hover
    {
        .leftPaneBackButtonIcon
        {
            animation-name:            leftPaneBackButtonLeftRight;
            animation-duration:        2s;
            animation-iteration-count: infinite;

            svg
            {
                *
                {
                    fill: $colorGray;
                }
            }
        }
    }

    &:active
    {
        .leftPaneBackButtonIcon
        {
            svg
            {
                *
                {
                    fill: $colorPrimary;
                }
            }
        }
    }
}

.leftPaneBackButtonIcon
{
    width:      10px;
    height:     17px;
    margin:     0 10px 0 0;
    transition: transform $defaultTransitionSpeed linear;;

    svg
    {
        *
        {
            fill: $colorGrayLighter;
        }
    }
}

.leftPaneBackButtonWrapper
{
    padding: 0 $leftPaneSidePadding 4px $leftPaneSidePadding;
}

@keyframes leftPaneBackButtonLeftRight
{
    50%
    {
        transform: translateX(-4px);
    }
}
