@import '../../styles/fonts';
@import '../../styles/colors';
@import '../../styles/zIndexes';

.indexAndCheckboxWrapperContainer
{
    display: flex;

    > :first-child
    {
        justify-content: center;
        align-items:     center;
    }

    > :last-child
    {
        flex: 1
    }

    .label
    {
        font-size:   14px;
        padding-top: 10px;
        width:       26px;
        color:       $colorGrayLight2;
        font-family: $mainFont;
    }

    .checkbox
    {
        width:       26px;
        padding-top: 10px;
    }
}

.blockEvents
{
    pointer-events: none;
}
