@import '../../styles/fonts';
@import '../../styles/colors';
@import '../../styles/variables';

.textContainer
{
    max-width: 664px;
    margin:    0 auto 10px auto;
    padding:   28px 0;
}

.textContainerAutoStyleChildren
{
    font-family: $mainFont;

    h3
    {
        font-size:   18px;
        margin:      0 0 22px 0;
        font-weight: bold;
    }

    p
    {
        margin: 0 0 22px 0;

        a
        {
            text-decoration: none;
            color:           $colorPrimary;
            transition:      color $defaultTransitionSpeed linear;

            &:hover
            {
                color: $colorPrimaryDarker;
            }
        }

        ul
        {
            margin-left: 20px;
        }
    }

}
