@import '../../styles/fonts';
@import '../../styles/colors';
@import '../../styles/variables';

.dropDownButton
{
    transition:  background-color $defaultTransitionSpeed linear;
    height:      42px;
    display:     flex;
    margin:      0 0 2px 0;
    line-height: 42px;
    user-select: none;

    a
    {
        text-decoration: none;
        display:         flex;
        flex:            1;
    }

    &:hover
    {
        background-color: $colorGrayLighter2;
    }
}

.dropDownButtonClickWrapper
{

}

.dropDownButtonInner
{
    font-family: $mainFont;
    font-size:   13px;
    color:       $colorGrayLight;
    display:     block;
    padding:     0 0 0 18px;
    flex:        1;

}
