@import '../../styles/fonts';
@import '../../styles/colors';

:global .productTableRow
{
}

:global .react-grid-Container .react-grid-Main .react-grid-Grid .react-grid-Viewport .react-grid-Canvas .productTableRow.dragging .react-grid-Row.react-grid-Row--even .react-grid-Cell,
:global .react-grid-Container .react-grid-Main .react-grid-Grid .react-grid-Viewport .react-grid-Canvas .productTableRow.dragging .react-grid-Row.react-grid-Row--odd .react-grid-Cell
{
    background: $colorPrimaryLighter2 !important;
}

.hitLimitation :global .react-grid-Cell__value,
.isIncompatible :global .react-grid-Cell__value,
.isEndOfLife :global .react-grid-Cell__value,
{
    color: $colorGrayLighter !important;
}
