@import '../../styles/fonts';
@import '../../styles/colors';
@import '../../styles/variables';

.projectListItem
{
    border-top: 1px solid $colorGrayLighter3;
    padding:    22px 25px;
    position:   relative;
    cursor:     pointer;
    transition: background-color $defaultTransitionSpeed linear,
                transform $defaultTransitionSpeed linear;

    &:first-child
    {
        border-top: 0;
    }

    &:hover
    {
        background: $colorPrimaryLighter2;

        .projectListItemCallToAction,
        .projectListItemDeleteButton
        {
            background: $colorPrimaryLighter2;

            &.projectListItemCallToAction
            {
                animation-name:            projectListItemCallToActionLeftRight;
                animation-duration:        2s;
                animation-iteration-count: infinite;
            }
        }
    }

    &:active
    {
        color: $colorPrimary !important;
    }
}

.projectListItemTitle
{
    font-size:   24px;
    font-family: $mainFont;
    color:       $colorPrimary;
    margin:      0 0 9px 0;
    position:    relative;
    display:     inline-block;
}

.projectListItemDetailList
{
    list-style: none;
    display:    flex;
}

.projectListItemDetailListTitle,
.projectListItemDetailListValue
{
    font-size:   14px;
    color:       $colorGrayLight;
    font-family: $mainFont;
    display:     inline-block;
}

.projectListItemDetailListTitle
{
    margin-right: 2px;
    font-weight:  300;
}

.projectListItemDetailListValue
{
    margin-right:  34px;
    font-weight:   600;
    width:         160px;
    text-overflow: ellipsis;
    overflow:      hidden;
    white-space:   nowrap;
}

.projectListItemDetailListValueDate
{
    overflow: unset;
}

.projectListItemCallToAction,
.projectListItemDeleteButton
{
    $callToActionSize: 45px;

    background:        $colorGrayLighter2;
    width:             $callToActionSize;
    height:            $callToActionSize;
    position:          absolute;
    top:               50%;
    margin:            -(calc($callToActionSize / 2)) 0 0 0;
    border-radius:     calc($callToActionSize / 2);
    display:           flex;
    align-items:       center;
    justify-content:   center;
    transition:        background-color $defaultTransitionSpeed linear;

    &.projectListItemCallToAction
    {
        right: 30px;

        svg
        {
            *
            {
                fill: $colorPrimary;
            }
        }
    }

    &.projectListItemDeleteButton
    {
        right: 90px;

        svg
        {
            *
            {
                fill: $colorGrayLighter;
            }
        }
    }

    &:hover
    {
        svg
        {
            *
            {
                fill: $colorPrimary;
            }
        }
    }
}

@keyframes projectListItemCallToActionLeftRight
{
    50%
    {
        transform: translateX(4px);
    }
}
