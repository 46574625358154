@import '../../styles/fonts';
@import '../../styles/colors';
@import '../../styles/variables';

.productConfigurationPane
{
    padding-top: 30px;
}

.productConfigurationPaneSlots
{
    padding: 0 $leftPaneSidePaddingSmall 20px $leftPaneSidePaddingSmall;
}

.productConfigurationPaneQuickSearch
{
    font-family: $mainFont;
    position:    relative;
    padding:     10px;
    background:  $colorGrayLighter2;
    margin:      0 $leftPaneSidePaddingSmall 20px $leftPaneSidePaddingSmall;
    box-shadow:  0 1px 2px 0 $colorGrayLighter;
    cursor:      pointer;
    transition:  background-color $defaultTransitionSpeed linear,
                 border-color $defaultTransitionSpeed linear;
    border-left: 2px solid $colorGrayLighter2;

    > *:not(:last-child)
    {
        margin-bottom: 10px;
    }
}
