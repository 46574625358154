@import '../../styles/fonts';
@import '../../styles/colors';

.contactItemWrapper
{
    display:         flex;
    padding:         0 50px;
    justify-content: space-around;
    max-width:       778px;
    margin:          40px auto 10px auto;
}
