@import '../../styles/fonts';
@import '../../styles/colors';

.dataTransferLabel
{
    display: inline-flex;
    cursor:  help;
}

.dataTransferLabelWrapper
{
    display:  flex;
    overflow: hidden;
}

.dataTransferLabelLeft
{
    justify-content: flex-start;
}

.dataTransferLabelRight
{
    justify-content: flex-end;
    overflow:        hidden;
    text-overflow:   ellipsis;
}

.dataTransferText
{
    overflow:        hidden;
    text-overflow:   ellipsis;
}

.dataTransferLabelIconWrapper
{
    display: inline-block;
    width:   20px;
    padding: 0 10px 0 0;
}
