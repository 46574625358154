@import '../../styles/fonts';
@import '../../styles/colors';

.selectedProductSlotImageNumbering
{
    position:    absolute;
    font-size:   14px;
    font-family: $mainFont;
}

.selectedProductSlotImageNumberingText
{

}

.selectedProductSlotImageNumberingExtender
{

}

.selectedProductSlotImageNumberingMatrix
{
    .selectedProductSlotImageNumberingText
    {
        background:    $colorWhite;
        width:         20px;
        height:        20px;
        border-radius: 10px;
        font-size:     12px;
        line-height:   20px;
        text-align:    center;
    }

    .selectedProductSlotImageNumberingWarningItemIconWrapper
    {
        background:      $colorWhite;
        width:           28px;
        height:          28px;
        border-radius:   14px;
        font-size:       12px;
        line-height:     20px;
        margin:          0 2px;
        display:         flex;
        align-items:     center;
        justify-content: center;

        :global .tooltipWrapperOrange
        {
            svg
            {
                margin-top: -3px;
            }
        }
    }

    &.selectedProductSlotImageNumberingVertical
    {
        transform: rotate(180deg) !important;

        .selectedProductSlotImageNumberingWarningWrapper
        {
            margin-bottom: -6px !important;

            .selectedProductSlotImageNumberingWarningItemIconWrapper
            {
                justify-content: center;

                :global .tooltipWrapper
                {
                    transform: rotate(-90deg) scale(0.9) !important;
                }
            }
        }
    }
}

.selectedProductSlotImageNumberingMatrixCpu
{
    .selectedProductSlotImageNumberingText
    {
        background: $colorYellow;
        color:      $colorBlack;
    }
}

.selectedProductSlotImageNumberingMatrixPowerSupplyUnit
{
    .selectedProductSlotImageNumberingText
    {
        background: $colorPurple;
        color:      $colorWhite;
    }
}

.selectedProductSlotImageNumberingHorizontal
{
    &.selectedProductSlotImageNumberingExtender
    {
        width:           100%;
        text-align:      center;
        display:         flex;
        align-items:     center;
        justify-content: center;

        &.selectedProductSlotImageNumberingRowEven
        {
            bottom: -24px;
        }

        &.selectedProductSlotImageNumberingRowOdd
        {
            top: -26px;
        }
    }

    &.selectedProductSlotImageNumberingMatrix
    {
        .selectedProductSlotImageNumberingWarningWrapper
        {
            position:   absolute;
            top:        0;
            margin-top: -4px;
        }
    }
}

.selectedProductSlotImageNumberingVertical
{
    bottom:          0;
    text-align:      center;
    height:          100%;
    width:           90px;
    display:         flex;
    justify-content: flex-end;
    align-items:     center;

    .selectedProductSlotImageNumberingWarningWrapper
    {
        position: absolute;
        bottom:   50%;
        margin:   0 0 -10px 0;

        :global .tooltipWrapper
        {
            transform:        rotate(-90deg);
            transform-origin: center;
        }
    }

    &.selectedProductSlotImageNumberingRowEven
    {

    }

    &.selectedProductSlotImageNumberingRowOdd
    {

    }

    &.selectedProductSlotImageNumberingExtender
    {

    }

    &.selectedProductSlotImageNumberingMatrix
    {

    }

    .selectedProductSlotImageNumberingText
    {
        transform: rotate(-90deg);

    }
}

.selectedProductSlotImageNumberingRowEven
{

}

.selectedProductSlotImageNumberingRowOdd
{

}

.selectedProductSlotImageNumberingWarningItem
{
    height: 20px;
}

.selectedProductSlotImageNumberingWarningItemIconWrapper
{

}

.selectedProductSlotImageNumberingWarningWrapper
{
    display: flex;
    margin:  0 0 0 5px;
}

.selectedProductSlotImageNumberingContent
{
    display: flex;
}
