@import '../../styles/fonts';
@import '../../styles/colors';
@import '../../styles/variables';

$inputPadding: 13px;

.inputWrapper
{
    display:        flex;
    flex-direction: column;
    margin:         0 0 22px 0;
    position:       relative;
    flex:           1;
}

.inputWrapperContent
{
    position:   relative;
    display:    flex;
    box-sizing: content-box;
}

.inputWrapperWithTooltip
{
    .inputWrapperContent
    {
        padding-right: 30px;
    }
}

.inputWrapperInput
{
    flex:          1;
    border:        1px solid $colorGrayLighter3;
    height:        36px;
    border-radius: 3px;
    font-family:   $mainFont;
    font-size:     14px;
    font-weight:   normal;
    font-style:    normal;
    padding:       0 $inputPadding 0 $inputPadding;
    resize:        vertical;
    min-width:     0;
    transition:    border-color $defaultTransitionSpeed linear;
    outline:       none;
    user-select:   text;

    &[type="number"]::-webkit-outer-spin-button,
    &[type="number"]::-webkit-inner-spin-button
    {
        -webkit-appearance: none;
        margin:             0;
    }

    &[type="number"]
    {
        -moz-appearance: textfield;
    }

    &:hover
    {
        border-color: $colorGrayLighter;
    }

    &:focus
    {
        border-color: $colorPrimary;
    }
}

.inputWrapperInputTextarea
{
    min-height: 100px;
    max-height: 400px;
    padding:    $inputPadding;
}

.inputWrapperLabel
{
    color:          $colorGrayLighter;
    font-size:      13px;
    font-weight:    600;
    display:        block;
    font-family:    $mainFont;
    text-transform: uppercase;
    margin:         0 0 6px 0;
}

.inputWrapperTooltip
{
    position: absolute;
    top:      10px;
    right:    0;
}
