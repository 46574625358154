//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/fonts';
@import '../../styles/colors';
@import '../../styles/variables';

.colorButton
{
    min-width:       132px;
    height:          36px;
    font-family:     $mainFont;
    font-size:       14px;
    display:         inline-block;
    text-transform:  uppercase;
    line-height:     36px;
    cursor:          pointer;
    transition:      background-color $defaultTransitionSpeed linear,
                     color $defaultTransitionSpeed linear;
    user-select:     none;
    text-align:      center;
    font-weight:     600;
    padding:         0 10px;
    white-space:     nowrap;
    text-decoration: none;

    &.disabled
    {
        pointer-events: none;
        opacity:        0.5;
    }
}

.colorButtonOrange
{
    background: $colorPrimary;
    color:      $colorWhite;

    &:hover
    {
        background-color: $colorPrimaryDarker;
    }

    &:active,
    &:focus
    {
        background-color: $colorPrimaryLighter;
    }

    svg
    {
        *
        {
            fill: $colorWhite;
        }
    }
}

.colorButtonRed
{
    background: $colorRedDark;
    color:      $colorWhite;

    &:hover
    {
        background-color: $colorRedDarker;
    }

    &:active,
    &:focus
    {
        background-color: $colorRedDarker;
    }

    svg
    {
        *
        {
            fill: $colorWhite;
        }
    }
}

.colorButtonContent
{
    display:         flex;
    height:          100%;
    width:           100%;
    justify-content: center;
    align-content:   center;
}

.colorButtonIconWrapper
{
    width:  18px;
    height: 100%;
    margin: 0 9px 0 0;
}

.colorButtonTextWrapper
{

}
