@import '../../styles/fonts';
@import '../../styles/colors';
@import '../../styles/zIndexes';

.indicator
{
    position:      absolute;
    top:           46px;
    left:          10px;
    z-index:       $zIndexZoomAndPanControlButtons;
    width:         38px;
    border-radius: 3px;
    height:        38px;
    background:    #dee0e0;
    cursor:        help;
}

.offsetIcon
{
    top: 105px;
}
