@import '../../styles/fonts';
@import '../../styles/colors';
@import '../../styles/variables';
@import '../../styles/zIndexes';

.headerDropZone
{
    position:       absolute;
    top:            0;
    left:           0;
    width:          100%;
    height:         100%;
    background:     $colorPrimary;
    z-index:        $zIndexHeaderDropZone;
    padding:        6px;
    display:        flex;
    box-sizing:     border-box;
    opacity:        0;
    transition:     opacity $defaultTransitionSpeed linear,
                    background-color $defaultTransitionSpeed linear;
    pointer-events: none;
}

.headerDropZoneIsOver
{
    background: $colorPrimaryDarker;
}

.headerDropZoneCanDrop
{
    opacity:        1;
    pointer-events: all;
}

.headerDropZoneInner
{
    border:          2px dashed $colorWhite;
    border-radius:   4px;
    flex:            1;
    display:         flex;
    justify-content: center;
    align-items:     center;
}

.headerDropZoneExpand
{
    width:   100%;
    height:  100%;
    display: flex;
    padding: 0 0 30px 0;
}

.headerDropZoneInnerIcon
{
    width:  17px;
    height: 20px;

    svg
    {
        *
        {
            fill: $colorWhite;
        }
    }
}

.headerDropZoneInnerText
{
    font-size:   14px;
    color:       $colorWhite;
    font-family: $mainFont;
    padding:     0 0 0 12px;
}
