@import '../../styles/fonts';
@import '../../styles/colors';
@import '../../styles/variables';

$padding: 15px;

.groupedChassisCollapserContainer
{
    margin-left:   -($padding);
    margin-right:  -($padding);
    margin-bottom: 1px;
    transition:    margin-right 0.1s, background 0.1s, border-top 0.1s;

    &.collapsed
    {
        padding-bottom: unset;
        background:     $colorBlueLight2;
    }
}

.groupedChassisCollapserContainer + .groupedChassisCollapserContainer
{
    margin-top: 0;
}

.groupedChassisCollapser
{
    background:  $colorBlueLight2;
    height:      30px;
    transition:  background-color $defaultTransitionSpeed linear;
    display:     flex;
    align-items: center;
    text-align:  left;
    user-select: none;
    padding:     8px 0 8px 15px;
    font-size:   18px;

    &:hover
    {
        cursor: pointer;
    }

    &.collapsed
    {
        svg
        {
            transform: scaleY(1);
        }
    }
}

.groupedChassisChildrenContainer
{
    padding:    $padding $padding $padding;
    transition: padding $defaultTransitionSpeed;

    &.collapsed
    {
        padding: 0 $padding $padding $padding;
    }
}

.groupedChassisCollapserIconWrapper
{
    height: 12px;
    width:  12px;
    margin: 0 5px 0 0;

    svg
    {
        *
        {
            fill: $colorWhite;
        }
    }
}

.groupedChassisCollapserText
{
    color:           $colorGray;
    text-transform:  uppercase;
    flex:            1;
    display:         flex;
    flex-direction:  row;
    justify-content: space-between;
    font-weight:     600;
    padding-left:    $padding + 3;
    padding-right:   $padding + 15px;
    font-size:       14px;
    font-family:     $mainFont;

    .text
    {
        flex: 1;
    }

    .count
    {
        font-size:       14px;
        display:         flex;
        flex-direction:  row;
        justify-content: center;
        align-items:     center;
        font-weight:     normal;

        > span
        {
            margin-left: 8px;
            width:       unset;
        }
    }

    svg
    {
        transform: scaleY(-1);
    }
}
