@import '../../styles/fonts';
@import '../../styles/colors';
@import '../../styles/variables';

.leftPaneAddButton
{
    cursor:      pointer;
    display:     flex;
    align-items: center;
    user-select: none;
}

.leftPaneAddButtonThemeProducts
{
    justify-content: flex-start;
    padding:         0 0 0 $leftPaneSidePaddingSmall;

    svg
    {
        *
        {
            fill: $colorPrimary;
        }
    }

    .leftPaneAddButtonText
    {
        font-size: 18px;
        color:     $colorGray;
    }
}

.leftPaneAddButtonThemeSingleOrder
{
    justify-content: center;
    padding:         0;
    height:          40px;

    svg
    {
        *
        {
            fill: $colorGrayLighter;
        }
    }

    .leftPaneAddButtonText
    {
        font-size:       14px;
        color:           $colorGrayLight;
        text-decoration: underline;
    }
}

.leftPaneAddButtonIcon
{
    display: inline-block;
    width:   16px;
    height:  16px;
    margin:  0 8px 0 0;
}

.leftPaneAddButtonText
{
    font-family: $mainFont;
    transition:  color $defaultTransitionSpeed linear;

    &:hover
    {
        color: $colorPrimary;
    }
}
