@import '../../styles/fonts';
@import '../../styles/colors';
@import '../../styles/variables';
@import '../../styles/zIndexes';

.modalWrapper
{
    background:          $colorBlack80;
    z-index:             $zIndexModalWrapper;
    height:              100%;
    width:               100%;
    position:            fixed;
    top:                 0;
    left:                0;
    display:             flex;
    justify-content:     center;
    align-items:         center;
    cursor:              pointer;
    overscroll-behavior: contain;
}

.modalWrapperUnclosable
{
    cursor: default;
}

.modalContainer
{
    cursor:        default;
    border-radius: 3px;
    overflow:      hidden;
}

.modalContainerTypeDialog
{
    width: 60%;
}

.modalContainerTypePrompt
{
    width: 430px;
}

.modalContainerTypeTable
{
    height: 80%;
    width:  80%;

    .modalContent
    {
        height:     80%;
        box-sizing: border-box;
        padding:    0;
    }
}

.modalContent
{
    padding:    $overlaySidePadding;
    background: $colorWhite;
}

.modalHeader
{
    height:      60px;
    background:  $colorPrimary;
    padding:     0 $overlaySidePadding 0 $overlaySidePadding;
    position:    relative;
    display:     flex;
    align-items: center;

    h2
    {
        font-family: $mainFont;
        font-size:   18px;
        font-weight: 600;
        color:       $colorWhite;
        margin:      -1px 0 0 0;
    }
}

.modalHeaderCloseButton
{
    position:   absolute;
    top:        50%;
    right:      $overlaySidePadding;
    width:      12px;
    height:     12px;
    margin:     -6px 0 0 0;
    display:    block;
    cursor:     pointer;
    overflow:   hidden;
    align-self: flex-end;

    svg
    {
        *
        {
            fill: $colorWhite;
        }
    }

    &:hover
    {
        svg
        {
            *
            {
                fill: $colorGray;
            }
        }
    }
}

.modalFooter
{
    height:         60px;
    background:     $colorGrayLighter4;
    padding:        0 $overlaySidePadding 0 $overlaySidePadding;
    display:        flex;
    flex-direction: row;
}

.modalFooterLeft
{
    flex:            0 auto;
    display:         flex;
    align-items:     center;
    justify-content: flex-start;

    span:nth-child(n+2)
    {
        margin-left: 27px;
    }
}

.modalFooterRight
{
    flex:            1;
    align-items:     center;
    justify-content: flex-end;
    display:         flex;
}
