@import '../../styles/fonts';
@import '../../styles/colors';

.projectListEmptyItem
{
    flex:       1;
    padding:    52px 100px 0 100px;
    height:     100%;
    text-align: center;

    h3
    {
        font-family: $mainFont;
        font-size:   24px;
        font-weight: normal;
        color:       $colorGray;
        margin:      0 0 17px 0;
    }

    p
    {
        font-family:  $mainFont;
        font-size:    14px;
        font-weight:  normal;
        font-style:   normal;
        font-stretch: normal;
        color:        $colorGray;
        margin:       0 0 24px 0;
    }
}
