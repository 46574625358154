//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/fonts';
@import '../../styles/colors';
@import '../../styles/zIndexes';
@import '../../styles/variables';

.imagePreview
{
    border-radius: 4px;
    position:      relative;
    overflow:      hidden;
    width:         156px;
    height:        100px;
    cursor:        pointer;

    &:hover
    {
        .imagePreviewOverlay
        {
            opacity: 1;
        }
    }
}

.imagePreviewImage
{
    width:      100%;
    height:     100%;
    object-fit: cover;
}

.imagePreviewOverlay
{
    border-radius:   4px;
    background:      $colorGrayLight2_80;
    top:             0;
    left:            0;
    width:           100%;
    height:          100%;
    position:        absolute;
    display:         flex;
    align-items:     center;
    justify-content: center;
    opacity:         0;
    transition:      opacity $defaultTransitionSpeed linear;
}

.imagePreviewOverlayIcon
{
    $size:         50px;

    width:         $size;
    height:        $size;
    border-radius: calc($size / 2);
    background:    $colorGrayLighter3_30;
    display:       block;

    svg
    {
        *
        {
            fill: $colorWhite;
        }
    }
}

:global .react-images__blanket
{
    z-index: $zIndexGalleryBackground !important;
}

:global .react-images__positioner
{
    z-index: $zIndexGalleryContent !important;
}
