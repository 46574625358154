@import '../../styles/fonts';
@import '../../styles/colors';

.specificationList
{
    position: relative;
    margin:   0 0 25px 0;
}

.specificationListTitle
{
    color:          $colorGrayLighter;
    font-size:      13px;
    text-transform: uppercase;
    font-family:    $mainFont;
    margin:         0 0 6px 0;
}

.specificationListTooltip
{
    position: absolute;
    top:      0;
    right:    0;
}

.specificationListInner
{
    background: $colorGrayLighter2;
    padding:    0;
    display:    flex;
    flex-wrap:  wrap;
}
