@import '../../styles/fonts';
@import '../../styles/colors';

.textLong
{
    font-family: $mainFont;
    font-size:   14px;
    color:       $colorGray;
    line-height: 1.5;

    a
    {
        color: $colorPrimary;
    }
}

.textLongBreakAfter
{
    margin: 0 0 14px 0;
}
