//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/fonts';
@import '../../styles/colors';
@import '../../styles/variables';

.contactItem
{
    font-family: $mainFont;
    flex:        1;
    padding:     0 0 0 55px;
    position:    relative;
    max-width:   200px;
    box-sizing:  content-box;

    h3
    {
        color:     $colorGray;
        font-size: 20px;
        padding:   6px 0 0 0;
        margin:    0 0 20px 0;
    }

    p
    {
        font-size:   14px;
        line-height: 21px;
        max-width:   200px;
        margin:      0 0 5px 0;
        min-height:  44px;

        a
        {
            font-weight:     bold;
            color:           $colorPrimary;
            text-decoration: none;
            transition:      color $defaultTransitionSpeed linear;

            &:hover
            {
                text-decoration: underline;
            }
        }
    }
}

.contactItemIconWrapper
{
    $contactItemIconWrapperSize: 40px;

    width:                       $contactItemIconWrapperSize;
    height:                      $contactItemIconWrapperSize;
    background:                  $colorPrimary;
    position:                    absolute;
    top:                         0;
    left:                        0;
}
