@import '../../styles/fonts';
@import '../../styles/colors';
@import '../../styles/variables';

.actionGroupItem
{
    position: relative;
    margin:   0 0 11px 0;

    &:last-child
    {
        margin-bottom: 0;
    }
}

.actionGroupItemIconWrapper
{
    width:   20px;
    height:  20px;
    margin:  0 6px 0 0;
    display: block;
}

.actionGroupItemLink
{
    color:          $colorPrimary;
    font-size:      14px;
    font-family:    $mainFont;
    transition:     color $defaultTransitionSpeed linear;
    user-select:    none;
    cursor:         pointer;
    display:        flex;
    flex-direction: row;

    svg
    {
        *
        {
            fill: $colorGrayLighter;
        }
    }

    &:hover
    {
        color: $colorPrimaryDarker;

        svg
        {
            *
            {
                fill: $colorPrimaryDarker;
            }
        }
    }

    &:active
    {
        color: $colorGrayLight;
    }
}

.actionGroupItemTooltip
{
    position: absolute;
    top:      0;
    right:    0;
}
