@import '../../styles/fonts';
@import '../../styles/colors';

.rightPaneProductTitle
{
    font-size:   18px;
    color:       $colorPrimary;
    font-family: $mainFont;
    margin:      0 0 21px 0;
}

.rightPaneProductTitleDeleteButton
{
    background-size: contain;
    color:           $colorGrayLight;
    font-size:       11px;
    font-family:     $mainFont;
    cursor:          pointer;
    display:         block;
    position:        absolute;
    top:             0;
    right:           0;
    width:           14px;
    overflow:        hidden;
    text-indent:     -9999px;

    svg
    {
        *
        {
            fill: $colorGrayLighter;
        }
    }

    &:hover
    {
        svg
        {
            *
            {
                fill: $colorPrimary;
            }
        }
    }
}

.rightPaneProductTitleWrapper
{
    position: relative;
}

