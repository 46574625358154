@import '../../styles/fonts';
@import '../../styles/colors';

.splitPaneManager
{
    width:           116px;
    display:         flex;
    font-family:     $mainFont;
    justify-content: space-around;
}

.splitPaneManagerButton
{
    height:              30px;
    width:               38px;
    background-color:    $colorGrayLight;
    background-position: center center;
    background-origin:   content-box;
    background-size:     contain;
    background-repeat:   no-repeat;
    position:            relative;
    display:             inline-block;
    box-sizing:          border-box;
    padding:             6px;
    margin:              0 0 12px 0;
    cursor:              pointer;
}

.iconWrapper
{
    width:        20px;
    height:       18px;
    padding-left: 3px;
}

.splitPaneManagerButtonText
{
    font-size:   11px;
    color:       $colorGrayLighter;
    display:     block;
    width:       38px;
    text-align:  center;
    position:    absolute;
    left:        0;
    bottom:      -16px;
    user-select: none;
}

.splitPaneManagerButtonPaneVisible,
.splitPaneManagerButtonBottomPaneVisible,
.splitPaneManagerButtonLeftPaneVisible,
.splitPaneManagerButtonRightPaneVisible
{
    svg
    {
        :global
        {
            .color
            {
                fill: $colorPrimary;
            }
        }
    }
}
