@import '../../styles/fonts';
@import '../../styles/colors';

.actionCancelAndConfirmButton
{
    display:         flex;
    background:      $colorGrayLighter3;
    padding:         10px;
    justify-content: space-around;
    align-items:     center;
    height:          36px;
}
