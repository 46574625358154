@import '../../styles/fonts';
@import '../../styles/colors';

.tipWrapper
{
    h3
    {
        color:          $colorGrayLighter;
        font-size:      13px;
        text-transform: uppercase;
        font-family:    $mainFont;
        margin:         0 0 6px 0;
    }

    p
    {
        background:    $colorGrayLighter2;
        display:       flex;
        flex-wrap:     wrap;
        color:         $colorGrayLight;
        font-size:     14px;
        font-family:   $mainFont;
        flex:          50%;
        padding:       8px 9px 8px 9px;
        overflow:      hidden;
        text-overflow: ellipsis;
    }
}

.tipWrapperRightPane
{

}

.tipWrapperLeftPane
{
    margin: 0 16px;
}
