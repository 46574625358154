@import '../../styles/fonts';
@import '../../styles/colors';
@import '../../styles/variables';

.productQuickSearchWrapper
{
    flex: 1;
}

.quickSearchWrapper
{
    display: flex;
}

.tooltipWrapper
{
    margin: 0 0 0 10px;
}
