@import '../../styles/fonts';
@import '../../styles/colors';
@import '../../styles/variables';

.productQuickSearchWrapper
{
    flex: 1;
}

.quickSearchWrapper
{
    padding:       10px $leftPaneSidePadding 20px $leftPaneSidePadding;
    border-bottom: 1px solid $colorGrayLighter3;
    display:       flex;
}

.tooltipWrapper
{
    margin: 0 0 0 10px;
}
