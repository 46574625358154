@import '../../styles/fonts';
@import '../../styles/colors';

.printPreviewOverlayFrameWrapper
{
    margin: -27px;
}

.printPreviewOverlayDebugContainer
{
    padding: 27px;
}
