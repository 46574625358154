@import '../../styles/fonts';
@import '../../styles/colors';
@import '../../styles/zIndexes';

.clearCacheInvalidator
{
    background:      $colorWhite80;
    position:        absolute;
    top:             0;
    left:            0;
    width:           100%;
    height:          100%;
    z-index:         $zIndexClearCacheInvalidator;
    font-family:     $mainFont;
    font-weight:     normal;
    display:         flex;
    align-items:     center;
    justify-content: center;
    flex-direction:  column;

    p
    {
        margin: 0 0 50px 0;
    }
}
