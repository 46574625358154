@import '../../styles/fonts';
@import '../../styles/colors';
@import '../../styles/variables';

.textButton
{
    text-transform: uppercase;
    font-family:    $mainFont;
    user-select:    none;
    cursor:         pointer;
    transition:     background-color $defaultTransitionSpeed linear,
                    color $defaultTransitionSpeed linear;
}

.textButtonSlim
{
    font-size:      13px;
    text-transform: uppercase;
    padding:        0 5px;
    border-radius:  3px;
    opacity:        0.8;
    transition:     opacity $defaultTransitionSpeed linear,
                    color $defaultTransitionSpeed linear;

    &:hover
    {
        opacity: 1;
    }
}

.textButtonBlack
{
    color:     $colorGrayLight;
    font-size: 14px;

    &:hover
    {
        color: $colorBlack;
    }

    &:active,
    &:focus
    {
        color: $colorGrayLighter;
    }
}

.textButtonGraySlim
{
    @extend .textButtonSlim;

    color:      $colorGrayLighter;
    background: $colorGrayLighter2;

    &:hover
    {
        color: $colorGrayLighter;
    }
}

.textButtonOrange
{
    color:     $colorPrimary;
    font-size: 14px;
}

.textButtonOrangeSlim
{
    @extend .textButtonSlim;

    color:      $colorPrimary;
    background: $colorPrimaryLighter2;
}
