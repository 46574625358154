//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/fonts';
@import '../../styles/colors';

.statusBar
{
    $height:       24px;

    line-height:   $height;
    background:    $colorWhite;
    border-bottom: 2px solid $colorGrayLighter3;
    color:         $colorGrayLight;
    font-size:     14px;
    text-align:    center;
    font-family:   $mainFont;
    display:       flex;
    padding:       6px 10px;
}

.statusBarInner
{
    border-right: 1px solid $colorGrayLighter3;
    padding:      0 10px;

    &:first-child
    {
        padding-left: 0;
    }

    &:last-child
    {
        padding-right: 0;
        border-right:  0;
    }
}

.statusBarBadge
{
    background:    $colorGrayLighter3;
    height:        19px;
    text-align:    center;
    color:         $colorGrayLight2;
    font-size:     14px;
    min-width:     11px;
    border-radius: 20px;
    display:       inline-block;
    font-family:   $mainFont;
    font-weight:   bold;
    padding:       2px 6px;
    position:      relative;
    top:           0;
    line-height:   19px;
    margin:        0 10px 0 0;

    &:last-child
    {
        margin-right: 0;
    }
}

.statusBarDetailButton
{
    display:         flex;
    text-decoration: none;
    color:           $colorPrimary;
    font-family:     $mainFont;
    font-weight:     700;
    font-size:       14px;

    .statusBarDetailButtonIconWrapper
    {
        margin-right: 5px;

        svg
        {
            height: 20px;

            *
            {
                fill: $colorGrayLight3;
            }
        }
    }

    .statusBarDetailButtonIconText
    {
        .statusBarDetailButtonIconWrapper
        {

        }
    }

    &:hover
    {
        .statusBarDetailButtonIconWrapper
        {
            margin-right: 5px;

            svg
            {
                *
                {
                    fill: $colorPrimary;
                }
            }
        }
    }
}
