@import '../../styles/fonts';
@import '../../styles/colors';

.actionGroup
{
    background: $colorWhite;
    padding:    30px;
    border-top: 1px solid $colorGrayLighter3;

    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px)
    {
        padding: 15px;
    }
}

.actionGroupList
{
    list-style: none;
}

.actionGroupTitle
{
    color:          $colorGrayLighter;
    font-size:      13px;
    text-transform: uppercase;
    font-family:    $mainFont;
    margin:         0 0 8px 0;
}
