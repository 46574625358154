@import '../../styles/fonts';
@import '../../styles/colors';
@import '../../styles/variables';

.titleBanner
{
    height:           169px;
    background-image: linear-gradient(to left, $colorHeaderStart, $colorHeaderEnd);
}

.titleBannerModeApp
{
    .titleBannerTextImage
    {
        background:      url($bigLogoLeftImage) no-repeat;
        background-size: contain;
        padding:         0 0 0 130px;
    }
}

.titleBannerModeText
{

}

.titleBannerInner
{
    height:          100%;
    background:      url($bigLogoBackground) no-repeat bottom right;
    background-size: contain;
    display:         flex;
    justify-content: center;
    align-items:     center;
    flex-direction:  column;
}

.titleBannerText
{
    font-family:     $mainFont;
    font-weight:     normal;
    height:          89px;
    display:         flex;
    justify-content: center;
    align-items:     center;
    flex-direction:  column;
    color:           $colorBigLogoText;
    font-size:       20px;
    user-select:     none;

    @media only screen and (min-width: 1024px)
    {
        font-size: 50px;
    }

    strong
    {
        font-weight: bold;
        margin:      0 6px 0 0;
    }

    p
    {
        font-size: 12px;
        margin:    -3px 0 0 0;
        display:   none;

        @media only screen and (min-width: 1024px)
        {
            font-size: 25px;
            display:   block;
        }
    }
}
