@import './styles/fonts';
@import './styles/colors';
@import './styles/variables';
@import './styles/zIndexes';

html
{
    height:   100%;
    overflow: hidden;
}

body
{
    height:                  100%;
    margin:                  0;
    padding:                 0;
    -webkit-font-smoothing:  antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow:                hidden;
    min-width:               320px;
    overscroll-behavior:     none;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px)
{
    *
    {
        user-select:  none;
        touch-action: manipulation;
    }
}

:global #root
{
    height: 100%;
}

*
{
    margin:  0;
    padding: 0;
}

.appContent
{
    // This disables "bounce scrolling"
    // @see https://stackoverflow.com/questions/21209223/remove-bounce-on-scroll-in-browser-issue-with-positionfixed-div
    position: absolute;
    top:      0;
    bottom:   0;
    left:     0;
    right:    0;
    overflow: auto;
    margin:   0;
    padding:  0;
    height:   100%;
    width:    100%;
}

.designer
{
    display:        flex;
    height:         100%;
    flex-direction: column;
}

.designerMainSplitPane
{
    position: relative !important;
    top:      0 !important;
    left:     0 !important;
    flex:     1;
}

.help
{
    padding: 30px;
}

.helpSteps
{
    display:   flex;
    flex-wrap: wrap;
}

.home
{
    display:        flex;
    min-height:     100%;
    flex-direction: column;
    overflow:       hidden;
}

@media only screen and (min-width: 1024px)
{
    :global
    {
        .Resizer
        {
            position: relative;

            &:after
            {
                content:  '';
                position: absolute;
            }

            &.vertical
            {
                position: relative;

                &:after
                {
                    top:    0;
                    left:   0;
                    right:  -3px;
                    bottom: 0;
                }

                // This will increase the clickable area of the
                // vertical resizer
                &:before
                {
                    content:  '';
                    position: absolute;
                    width:    5px;
                    height:   100%;
                    top:      0;
                    left:     0;
                    z-index:  10000000;
                }
            }

            &.horizontal
            {
                &:after
                {
                    top:    -3px;
                    left:   0;
                    right:  0;
                    bottom: -3px;
                }
            }
        }
    }
}
