@import '../../styles/fonts';
@import '../../styles/colors';
@import '../../styles/variables';

.icon
{
    background-size: contain;
    width:           100%;
    display:         flex;
    justify-content: center;
    align-items:     center;
    height:          100%;
    position:        relative;

    svg
    {
        *
        {
            transition: fill $defaultTransitionSpeed linear;
        }
    }
}
