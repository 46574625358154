@import '../../styles/fonts';
@import '../../styles/colors';

$sidePadding: 9px;

.specificationListItemLeft,
.specificationListItemRight
{
    color:         $colorGrayLight;
    font-size:     14px;
    font-family:   $mainFont;
    flex:          50%;
    padding:       8px 0 8px 0;
    border-top:    1px solid $colorGrayLighter3;
    overflow:      hidden;
    text-overflow: ellipsis;
    box-sizing:    border-box;

    &.specificationListItemLeft
    {
        padding-left: $sidePadding;
    }

    &.specificationListItemRight
    {
        padding-right: $sidePadding;
    }

    &:nth-child(1),
    &:nth-child(2)
    {
        border-top: 0;
    }
}

.specificationListItemLeftDefaultAlert,
.specificationListItemRightDefaultAlert
{
    background: $colorPrimaryLighter2;
}

.specificationListItemLeftLongValue,
.specificationListItemRightLongValue
{
    flex:    100%;
    padding: $sidePadding;
}

.specificationListItemLeft
{
    text-align: left;
}

.specificationListItemLeftIconWrapper
{
    display: inline-block;
    margin:  0 6px 0 0;
    height:  10px;

    svg
    {
        position: relative;
        top:      3px;
    }
}

.specificationListItemRight
{
    text-align:  right;
    white-space: nowrap;
}

.specificationListItemLeftLongValue
{
    padding-bottom: 0;
}

.specificationListItemRightLongValue
{
    border-top:  0;
    padding-top: 0;
    text-align:  left;
    white-space: pre-wrap;
}
