//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/colors';
@import '../../styles/zIndexes';

.label
{
    display: flex;
}

.dataSheetIconWrapper,
.productImagePreviewIconWrapper
{
    display: inline-block;
    margin:  0 10px 0 0;

    &.dataSheetIconWrapper
    {
        cursor: pointer;
    }

    &.dataSheetIconWrapperNoFiles
    {
        opacity: 0.3;
    }

    svg
    {
        position: relative;
    }
}

.availabilityIconWrapper
{
    display:       inline-block;
    margin:        4px 6px 0 -20px;
    cursor:        pointer;
    width:         10px;
    height:        10px;
    border-radius: 50%;

    &.available
    {
        background-color: $colorGreen;
    }

    &.endOfLife
    {
        background-color: $colorGrayLighter3;
    }

    &.onRequest
    {
        background-color: $colorYellow;
    }

    &.lastTimeBuy
    {
        background-color: $colorRedDark;
    }
}
