@import '../../styles/fonts';
@import '../../styles/colors';
@import '../../styles/variables';

.headerContainer
{
    height:                $headerHeight;
    background:            $colorGray;
    display:               grid;
    grid-template-columns: 1fr 2fr 1fr;
    position:              relative;

    @media only screen and (display-mode: standalone) and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape)
    {
        border-top: $headerStatusBarPaddingHeight solid $colorGray;
    }
}

.headerContainerCenter
{
    display:         flex;
    align-items:     center;
    justify-content: space-around;
}

.headerContainerDracoSystemDesignerLogo
{
    background:      url($headerLeftLogo) no-repeat center center;
    background-size: contain;
    width:           150px;
    height:          42px;
    overflow:        hidden;
    text-indent:     -9999px;
}

.headerContainerIhseLogo
{
    background:      url($smallLogo) no-repeat center center;
    background-size: contain;
    width:           140px;
    height:          31px;
    overflow:        hidden;
    text-indent:     -9999px;
}

.headerContainerLeft
{
    display:         flex;
    align-items:     center;
    justify-content: left;
    padding:         0 0 0 $headerSidePadding;
}

.headerContainerRight
{
    display:         flex;
    justify-content: flex-end;
    align-items:     center;
    padding:         0 $headerSidePadding 0 0;
}
