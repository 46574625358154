@import '../../styles/fonts';
@import '../../styles/colors';
@import '../../styles/variables';
@import '../../styles/zIndexes';

.textDropZone
{
    box-sizing:     border-box;
    position:       absolute;
    top:            -1px;
    left:           0;
    right:          0;
    bottom:         -1px;
    background:     $colorGrayLight2_90;
    padding:        3px;
    display:        flex;
    transition:     opacity $defaultTransitionSpeed linear;
    opacity:        0;
    pointer-events: none;
    z-index:        $zIndexTextDropZone;
}

.textDropZoneRoundCornerStyleRound
{
    border-radius: 3px;
}

.textDropZoneRoundCornerStyleSquare
{
    border-radius: 0;
}

.textDropZoneCanDrop
{
    opacity: 0.4;
}

.textDropZoneInner
{
    box-sizing:      border-box;
    border:          1px dashed $colorWhite;
    border-radius:   4px;
    flex:            1;
    display:         flex;
    align-items:     center;
    justify-content: center;
}

.textDropZoneInnerText
{
    text-align:  center;
    font-size:   14px;
    font-family: $mainFont;
    color:       $colorWhite;
}

.textDropZoneDropValidationAllowed
{
    background: $colorGreenDark;

    .textDropZoneInner
    {
        opacity:      0;
        border-color: $colorGreen;

        .textDropZoneInnerText
        {
            color: $colorGreen;
        }
    }
}

.textDropZoneDropValidationForbidden
{
    color:          $colorRed;
    background:     $colorRedDark;
    pointer-events: none !important;

    .textDropZoneInner
    {
        opacity:      0;
        border-color: $colorRed;

        .textDropZoneInnerText
        {
            color: $colorRed;
        }
    }
}

.textDropZoneIsOver
{
    opacity:        1;
    pointer-events: all;

    .textDropZoneInner
    {
        opacity: 1;
    }
}
