@import '../../styles/fonts';
@import '../../styles/colors';
@import '../../styles/variables';

.leftPaneProductCategory
{
    border-bottom: 1px solid $colorGrayLighter3;
}

.leftPaneProductCategoryToggleControl
{
    position:        relative;
    height:          70px;
    display:         block;
    text-decoration: none;
    text-transform:  uppercase;
    font-weight:     600;
    padding:         0 0 0 $leftPaneSidePadding;
    line-height:     73px;
    font-size:       13px;
    cursor:          pointer;
}

.leftPaneProductCategoryToggleControlIcon
{
    display:    inline-block;
    width:      12px;
    height:     14px;
    transition: transform $defaultTransitionSpeed linear;
    margin:     0 10px 0 0;
}

.leftPaneProductCategoryToggleControlIconOpen
{
    transform: rotate(180deg);
}

.leftPaneProductCategoryToggleControlText
{
    color:       $colorGrayLighter;
    font-family: $mainFont;
    font-size:   13px;
    user-select: none;
    margin:      1px 0 0 0;
    position:    relative;
    top:         1px;
}

.leftPaneProductCategoryToggleControlAddButton,
.leftPaneProductCategoryToggleControlCloseButton
{
    position: absolute;
    top:      0;
    right:    $leftPaneSidePaddingSmall;
}

.leftPaneProductCategoryContent
{
    padding: 0 $leftPaneSidePaddingSmall 20px $leftPaneSidePaddingSmall;
}

.leftPaneProductCategoryContentWrapper
{
    position: relative;
}

.leftPaneProductCategoryContentWrapperWithMinHeight
{
    min-height: 200px;
}

.group + .product
{
    margin-top: 15px;
}

.product + .group
{
    margin-top: 15px;
}

.product:last-child
{
}
