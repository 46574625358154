@import '../../styles/fonts';
@import '../../styles/colors';
@import '../../styles/variables';

.selectedProductSlotImageRenderer
{
    position: absolute;
    cursor:   pointer;
}

.selectedProductSlotImageRendererImage
{
    width:           100%;
    height:          100%;
    background-size: contain;
}

.selectedProductSlotImageRendererSelectedOverlay
{
    background:     $colorPrimary;
    position:       absolute;
    top:            0;
    pointer-events: none;
    left:           0;
    width:          100%;
    height:         100%;
    opacity:        0;
    transition:     opacity $defaultTransitionSpeed linear;
}

.selectedProductSlotImageRendererSelectedOverlayVisible
{
    opacity: 0.4
}

.selectedProductSlotImageRendererDropZone
{
    box-sizing:     border-box;
    position:       absolute;
    top:            0;
    left:           0;
    width:          100%;
    height:         100%;
    background:     $colorGrayLight2_90;
    padding:        3px;
    pointer-events: none;
    display:        flex;
    transition:     opacity $defaultTransitionSpeed linear;
    opacity:        0;
}

.selectedProductSlotImageRendererDoubleSlotDropZoneWrapper
{
    position:       absolute;
    left:           0;
    height:         100%;
    width:          100%;
    pointer-events: none;
}

.selectedProductSlotImageRendererDoubleSlotDropZoneWrapperDragging
{
    pointer-events: all;
}

.selectedProductSlotImageRendererDoubleSlotDropZoneWrapperTop
{
    top: 0;
}

.selectedProductSlotImageRendererDoubleSlotDropZoneWrapperBottom
{
    top: 100%;
}
