@import '../../styles/fonts';
@import '../../styles/colors';

.overlayPane
{
    border-top: 1px solid $colorGrayLighter3;
    display:    flex;
    padding:    20px 0;

    &:first-child
    {
        border-top:  0;
        padding-top: 0;
    }

    &:last-child
    {
        padding-bottom: 0;
    }

    &.overlayPaneNoText
    {
        justify-content: flex-start;
        flex:            1;
    }
}

.overlayPaneLeft,
.overlayPaneRight
{
    display:     flex;
    align-items: center;
}

.overlayPaneLeft
{
    flex: 4;
}

.overlayPaneLeftText
{
    color:       $colorGrayLight;
    font-size:   14px;
    font-family: $mainFont;
    font-weight: normal;
    margin:      0 5px 0 0;

    &.overlayPaneLeftNoText
    {
        justify-content: flex-start;
    }
}

.overlayPaneRight
{
    flex:            6;
    justify-content: flex-end;
    align-items:     flex-end;
    flex-direction:  column;

    &.overlayPaneRightNoText
    {
        justify-content: flex-start;
    }
}
