//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/fonts';
@import '../../styles/colors';
@import '../../styles/variables';

.titleSubTitleButton
{
    position:        relative;
    padding:         15px 15px 15px 60px;
    margin:          -15px -15px -15px -15px;
    cursor:          pointer;
    user-select:     none;
    display:         block;
    text-decoration: none;
    transition:      background-color $defaultTransitionSpeed linear;

    &:hover:not(.disabled)
    {
        background: $colorPrimaryLighter2;
    }

    &.disabled
    {
        opacity: 0.7;
    }
}

.titleSubTitleButton + .titleSubTitleButton
{
    margin-top: 32px;
}

.titleSubTitleButtonDefault
{
    .titleSubTitleButtonIcon
    {
        background: $colorGrayLighter;

        svg
        {
            *
            {
                fill: $colorGrayLight3;
            }
        }
    }
}

.titleSubTitleButtonOrange
{
    .titleSubTitleButtonIcon
    {
        background: $colorPrimary;
    }
}

.titleSubTitleButtonIcon
{
    width:         32px;
    height:        32px;
    border-radius: 3px;
    position:      absolute;
    top:           19px;
    left:          15px;
    transition:    background-color $defaultTransitionSpeed linear;
    box-sizing:    border-box;
    padding:       7px;

    svg
    {
        *
        {
            fill: $colorWhite;
        }
    }
}

.titleSubTitleButtonSubTitle
{
    color:          $colorGrayLighter;
    font-size:      13px;
    font-family:    $mainFont;
    font-weight:    300;
    text-transform: uppercase;
    transition:     color $defaultTransitionSpeed linear;
}

.titleSubTitleButtonTitle
{
    color:       $colorGray;
    font-size:   18px;
    font-family: $mainFont;
    font-weight: 600;
    transition:  color $defaultTransitionSpeed linear;
}
