//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/fonts';
@import '../../styles/colors';

.helpBulletPoint
{
    display:        flex;
    flex-direction: row;
    margin:         0 20px 20px 0;
    width:          30%;
}

.helpBulletPointLeft
{
    padding: 0 15px 0 0;
}

.helpBulletPointNumber
{
    $bulletWidth:    28px;

    width:           $bulletWidth;
    height:          $bulletWidth;
    border-radius:   calc($bulletWidth / 2);
    background:      $colorGrayLighter2;
    border:          1px solid $colorGrayLighter3;
    color:           $colorGrayLight2;
    font-family:     $mainFont;
    font-size:       14px;
    line-height:     $bulletWidth;
    text-align:      center;
    justify-content: center;
    align-items:     center;
}

.helpBulletPointRight
{

}

.helpBulletPointText
{
    font-family: $mainFont;
    font-size:   14px;
    font-weight: normal;
    color:       $colorGrayLight2;
}

.helpBulletPointTitle
{
    font-family: $mainFont;
    font-size:   14px;
    font-weight: bold;
    color:       $colorGrayLight2;
    margin:      0 0 2px 0;
}

.helpBulletPointImageWrapper
{
    margin: 5px 0 0 0;
}
