//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.
$colorBlack:                  #000000;
$colorBlack80:                rgba(0, 0, 0, 0.8); // todo: $colorBlack_80
$colorBlue:                   #1cb1d6;
$colorBlueLight:              #d8eff7;
$colorBlueLight2:             #e7eff8;
$colorGray:                   #333333;
$colorGrayLight:              #505455;
$colorGrayLight2:             #4f5455;
$colorGrayLight2_50:          rgba(79, 84, 85, 0.5);
$colorGrayLight2_80:          rgba(79, 84, 85, 0.8);
$colorGrayLight2_90:          rgba(79, 84, 85, 0.9);
$colorGrayLight3:             #7b8282;
$colorGrayLighter:            #a8adae;
$colorGrayLighter2:           #f7f7f7;
$colorGrayLighter3:           #dee0e0;
$colorGrayLighter3_30:        rgba(222, 224, 224, 0.3);
$colorGrayLighter4:           #eeefef;
$colorGreen:                  #00e949;
$colorGreenDark:              #02ad58;
$colorGreenLight:             #d8fbdd;
$colorOrange:                 #ef7d00;
$colorOrangeDarker:           #cb5c00;
$colorOrangeLighter:          #ffb459;
$colorOrangeLighter2:         #ffe4c9;
$colorOrangeLighter3:         #fff2e3;
$colorPurple:                 #91219e;
$colorPurpleLight:            #e9d3ec;
$colorRed:                    #f0c3c0;
$colorRedDark:                #ce372e;
$colorRedDarker:              #7e211c;
$colorRedLight:               #f5d7d5;
$colorTransparent:            transparent;
$colorWhite:                  #ffffff;
$colorWhite50:                rgba(255, 255, 255, 0.5);
$colorWhite65:                rgba(255, 255, 255, 0.65);
$colorWhite70:                rgba(255, 255, 255, 0.70);
$colorWhite75:                rgba(255, 255, 255, 0.75);
$colorWhite80:                rgba(255, 255, 255, 0.8);
$colorYellowLight:            #fff3c8;
$colorYellow:                 #ffc400;
$colorBanner:                 #ecccca;
$colorBannerText:             $colorBlack;
$colorPrimary:                $colorOrange;
$colorPrimaryLighter:         $colorOrangeLighter;
$colorPrimaryLighter2:        $colorOrangeLighter2;
$colorPrimaryLighter3:        $colorOrangeLighter3;
$colorHeaderButtonBackground: $colorGrayLight;
$colorHeaderButtonBorder:     $colorGrayLight;
$colorHeaderButtonText:       $colorGrayLighter;
$colorHeaderButtonIcon:       $colorGrayLighter3;

@import "customColors";

:export
{
    colorBlack:           $colorBlack;
    colorBlack80:         $colorBlack80;
    colorGray:            $colorGray;
    colorGrayLight:       $colorGrayLight;
    colorGrayLight3:      $colorGrayLight3;
    colorGrayLighter:     $colorGrayLighter;
    colorGrayLighter2:    $colorGrayLighter2;
    colorGrayLighter3:    $colorGrayLighter3;
    colorOrange:          $colorOrange;
    colorOrangeDarker:    $colorOrangeDarker;
    colorOrangeLighter:   $colorOrangeLighter;
    colorOrangeLighter2:  $colorOrangeLighter2;
    colorOrangeLighter3:  $colorOrangeLighter3;
    colorPrimary:         $colorPrimary;
    colorPrimaryLighter:  $colorPrimaryLighter;
    colorPrimaryLighter2: $colorPrimaryLighter2;
    colorPrimaryLighter3: $colorPrimaryLighter3;
    colorTransparent:     $colorTransparent;
    colorWhite:           $colorWhite;
}
