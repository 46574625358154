@import '../../styles/fonts';
@import '../../styles/colors';

.productDragView
{
    background:  $colorWhite;
    padding:     16px 25px;
    box-shadow:  0 20px 50px 0 #0000004c;
    text-align:  center;
    font-size:   14px;
    font-family: $mainFont;
    color:       $colorGrayLight;
    float:       left;
    position:    relative;
    left:        -50%;
}

.productDragViewWrapper
{

}
