@import '../../styles/fonts';
@import '../../styles/colors';
@import '../../styles/variables';

.leftPaneSingleOrderCategory
{
    border-bottom: 1px solid $colorGrayLighter3;
}

.leftPaneSingleOrderCategoryAddButtonWrapper
{
    padding: 0 $leftPaneSidePaddingSmall;
    margin:  0 0 10px 0;
}

.leftPaneSingleOrderCategoryAddButtonWrapperInner
{
    position: relative;

}

.leftPaneSingleOrderCategoryToggleControl
{
    position:        relative;
    height:          70px;
    display:         block;
    text-decoration: none;
    text-transform:  uppercase;
    font-weight:     600;
    padding:         0 0 0 $leftPaneSidePadding;
    line-height:     73px;
    font-size:       13px;
    cursor:          pointer;
}

.leftPaneSingleOrderCategoryToggleControlIcon
{
    display:    inline-block;
    width:      12px;
    height:     14px;
    transition: transform $defaultTransitionSpeed linear;
    margin:     0 10px 0 0;
}

.leftPaneSingleOrderCategoryToggleControlIconOpen
{
    transform: rotate(180deg);
}

.leftPaneSingleOrderCategoryToggleControlText
{
    color:       $colorGrayLighter;
    font-family: $mainFont;
    font-size:   13px;
    user-select: none;
    margin:      1px 0 0 0;
    position:    relative;
    top:         1px;
}

.leftPaneSingleOrderCategoryToggleControlAddButton,
.leftPaneSingleOrderCategoryToggleControlCloseButton
{
    position: absolute;
    top:      0;
    right:    $leftPaneSidePaddingSmall;
}

.leftPaneSingleOrderCategoryContent
{
    padding: 0 $leftPaneSidePaddingSmall 10px $leftPaneSidePaddingSmall;
}

.leftPaneSingleOrderCategoryContentWrapper
{
    position: relative;
}

.leftPaneSingleOrderCategoryContentWrapperWithMinHeight
{
    min-height: 200px;
}
