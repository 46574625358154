@import '../../styles/fonts';
@import '../../styles/colors';
@import '../../styles/variables';
@import '../../styles/zIndexes';

.subLevelTabButton
{
    font-size:   14px;
    font-family: $mainFont;
    transition:  color $defaultTransitionSpeed linear;
    user-select: none;
    cursor:      pointer;
    font-weight: normal;
    line-height: 22px;
    padding:     0 3px;
    position:    relative;

    &:hover
    {
        color: $colorPrimary;
    }
}

$arrowWidth: 10px;

.subLevelTabButtonArrow
{
    width:          0;
    height:         0;
    border-left:    $arrowWidth solid transparent;
    border-right:   $arrowWidth solid transparent;
    border-bottom:  $arrowWidth solid $colorPrimary;
    position:       absolute;
    top:            20px;
    left:           50%;
    margin:         0 0 0 ((-1 * (calc($arrowWidth / 2))) - 4px);
    opacity:        0;
    user-select:    none;
    pointer-events: none;
    transition:     opacity $defaultTransitionSpeed linear;
}

.subLevelTabButtonDisabled
{
    color:  $colorGrayLighter !important;
    cursor: not-allowed;
}

.subLevelTabButtonSelected
{
    color: $colorPrimary;

    .subLevelTabButtonText
    {
        font-weight: 600;
    }

    .subLevelTabButtonArrow
    {
        opacity: 1;
    }
}

.subLevelTabButtonText
{
    font-weight: normal;
    font-size:   14px;
}
