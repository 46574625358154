//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/fonts';
@import '../../styles/colors';
@import '../../styles/variables';

.leftPaneProductItem
{
    padding:          9px $productItemSidePadding;
    border-bottom:    1px solid $colorGrayLighter3;
    box-shadow:       0 1px 2px 0 $colorGrayLighter;
    position:         relative;
    cursor:           pointer;
    background-color: $colorWhite;
    border-left:      2px solid $colorWhite;
    min-height:       25px;
    transition:       background-color $defaultTransitionSpeed linear,
                      border-left-color $defaultTransitionSpeed linear;

    &:last-child
    {
        border-bottom: 0;
    }

    &:hover
    {
        background-color:  $colorPrimaryLighter2 !important;
        border-left-color: $colorPrimaryLighter2;

        .leftPaneProductItemCallToAction
        {
            animation-name:            leftPaneProductItemCallToActionLeftRight;
            animation-duration:        2s;
            animation-iteration-count: infinite;
        }
    }

    h3
    {
        // This will overwrite the style of the
        // <ProductTitle> component
        font-size:     14px !important;
        margin-bottom: 0 !important;
        margin-top:    3px;
    }
}

.leftPaneProductLastSelected
{
    border-left-color: $colorPrimary !important;
    background-color:  $colorPrimaryLighter3 !important;
}

.leftPaneProductItemDragging
{
    opacity: 0;
}

.leftPaneProductItemNoHover
{
    background-color: $colorWhite !important;

    .leftPaneProductItemCallToAction
    {
        transform: translateX(0) !important;
    }
}

.leftPaneProductItemCallToAction
{
    $leftPaneLinkButtonIconSize: 24px;

    position:                    absolute;
    right:                       5px;
    top:                         20px;
    margin:                      (-1 * (calc($leftPaneLinkButtonIconSize / 2))) 0 0 0;
    display:                     block;
    width:                       $leftPaneLinkButtonIconSize;
    height:                      $leftPaneLinkButtonIconSize;
    transition:                  transform $defaultTransitionSpeed linear;
}

.leftPaneProductItemEven
{
    background-color: $colorWhite;
    border-color:     $colorWhite;
}

.leftPaneProductItemInformationWrapper
{
    display:         flex;
    flex-direction:  row;
    flex-wrap:       wrap;
    justify-content: space-between;
    margin:          0;
    position:        absolute;
    bottom:          12px;
    right:           30px;
}

.leftPaneProductItemInformationWrapperWithCustomName
{
    bottom: 8px;
}

.leftPaneProductItemOdd
{
    background-color: $colorGrayLighter2;
    border-color:     $colorGrayLighter2;
}

@keyframes leftPaneProductItemCallToActionLeftRight
{
    50%
    {
        transform: translateX(4px);
    }
}
