@import '../../styles/fonts';
@import '../../styles/colors';
@import '../../styles/variables';

.productQuickSelectWrapper
{
    position: relative;
}

.productQuickSelectEmptyText
{
    transition:     opacity $defaultTransitionSpeed linear;
    position:       absolute;
    color:          $colorGrayLighter;
    font-size:      14px;
    font-family:    $mainFont;
    top:            2px;
    left:           2px;
    opacity:        0;
    pointer-events: none;
}

.productQuickSelectEmptyTextDraggingActive
{
    opacity: 1;
}

.productQuickSelect
{
    transition: opacity $defaultTransitionSpeed linear;
}

.productQuickSelectDraggingActive
{
    opacity: 0;
}

.productQuickSelectFullOptionHeadline
{
    font-size:   11px;
    display:     block;
    font-weight: bold;
    font-family: $mainFont;
}

.productQuickSelectFullOptionDescription
{
    font-size:   11px;
    display:     block;
    font-weight: 300;
    font-family: $mainFont;
}

.productQuickSelectFullOptionDescriptionHighlighted
{
    font-weight:      bold;
    background-color: transparent;
}
