//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/fonts';
@import '../../styles/colors';
@import '../../styles/variables';
@import '../../styles/zIndexes';

.table
{
    height:   100%;
    position: relative;
}

$tableHeaderHeight:   29px;

:global .react-grid-Container
{
    height: 100%;

    .react-grid-Main
    {
        outline: none;
        height:  100%;

        .react-grid-Grid
        {
            height: 100%;
            border: 0 !important;

            .react-grid-Header
            {
                background: $colorPrimary;

                .react-grid-HeaderRow
                {
                    background: $colorPrimary;
                    border:     0 !important;
                    overflow:   hidden !important;

                    .react-grid-HeaderCell
                    {
                        box-sizing:     border-box;
                        background:     $colorPrimary;
                        font-family:    $mainFont;
                        font-size:      13px;
                        font-weight:    600;
                        color:          $colorWhite;
                        text-transform: uppercase;
                        line-height:    27px;
                        position:       relative;
                        border:         0;
                        padding:        3px 0 0 8px;

                        &:first-child
                        {
                            padding-left: 25px;
                        }

                        .react-grid-HeaderCell-sortable,
                        .widget-HeaderCell__value
                        {
                            white-space: pre-wrap;
                            line-height: 12px;
                            padding:     4px 0 0 0;

                            span
                            {
                                position: absolute;
                                top:      7px;
                                right:    26px;
                                z-index:  $zIndexTableSortArrow;
                            }
                        }

                        input
                        {
                            height:        30px;
                            border-radius: 3px;
                            border:        0;
                            color:         $colorGrayLight;
                            font-family:   $mainFont;
                            font-size:     14px;
                            padding:       0 10px;
                            outline:       none;
                            background:    $colorWhite;
                            width:         calc(100% - 27px);
                            box-sizing:    content-box;

                            &::placeholder
                            {
                                color: $colorGrayLighter;
                            }
                        }

                        &:has(.tooltipWrapper)
                        {
                            input
                            {
                                width:      calc(100% - 5px);
                                box-sizing: border-box;
                            }

                            .tooltipWrapper
                            {
                                margin-right: 2px;
                            }
                        }
                    }
                }
            }

            .react-grid-Viewport
            {
                .react-grid-Canvas
                {
                    .rdg-selected
                    {
                        display: none;
                    }

                    .react-grid-Row
                    {
                        &.react-grid-Row--even,
                        &.react-grid-Row--odd
                        {
                            &:hover
                            {
                                .react-grid-Cell
                                {
                                    background: $colorGrayLighter3 !important;
                                }
                            }
                        }

                        &.react-grid-Row--even
                        {
                            .react-grid-Cell
                            {
                                background: $colorGrayLighter2 !important;
                            }
                        }

                        &.react-grid-Row--odd
                        {
                            .react-grid-Cell
                            {
                                background: $colorWhite !important;
                            }
                        }

                        .react-grid-Cell
                        {
                            background: transparent !important;
                            padding:    0;
                            border:     0;
                            transition: background-color $defaultTransitionSpeed linear;

                            .react-grid-Cell__value
                            {
                                padding:     0 0 0 9px;
                                font-family: $mainFont;
                                font-size:   14px;
                                color:       $colorGrayLight;

                                // @see https://github.com/adazzle/react-data-grid/issues/245
                                > div
                                {
                                    > span
                                    {
                                        > div
                                        {
                                            text-overflow: ellipsis;
                                            overflow:      hidden;
                                            padding:       0 5px 0 0;
                                        }
                                    }
                                }
                            }

                            &:first-child
                            {
                                .react-grid-Cell__value
                                {
                                    padding: 0 0 0 29px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// @see https://github.com/adazzle/react-data-grid/issues/736
// This will fix the height of the table when a tab bar is placed on top of it.

$minHeight:           100%;
$minHeightCalculated: calc(100% - 70px);

:global .react-grid-Main
{
    height: $minHeight;
    height: $minHeightCalculated;
}

:global .react-grid-Container
{
    height: $minHeight;
    height: $minHeightCalculated;
}

:global .react-grid-Grid
{
    min-height: $minHeight !important;
    min-height: $minHeightCalculated;
}

:global .react-grid-Canvas
{
    height: $minHeight;
    height: $minHeightCalculated;
}

:global .without-tab-bar .react-grid-Main
{
    height: $minHeight !important;
}

:global .without-tab-bar .react-grid-Container
{
    height: $minHeight;
}

:global .without-tab-bar .react-grid-Grid
{
    height: $minHeight !important;
}

:global .without-tab-bar .react-grid-Canvas
{
    height: $minHeight !important;
}
