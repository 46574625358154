//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/fonts';
@import '../../styles/colors';

.deviceTypeLabel
{
    display: flex;
    cursor:  help;
}

.deviceTypeLabelLeft
{
    justify-content: flex-start;
}

.deviceTypeLabelRight
{
    justify-content: flex-end;
}

.deviceTypeText
{
    padding: 0 0 0 10px;
}

.deviceTypeLabelIconWrapper
{
    display: inline-block;
    width:   20px;
}
