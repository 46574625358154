@import '../../styles/fonts';
@import '../../styles/colors';

.productCategorySelectionPane
{
    display:         flex;
    height:          100%;
    justify-content: center;
    align-items:     center;
    flex-direction:  column;

    h3
    {
        font-size:   18px;
        font-family: $mainFont;
        color:       $colorGrayLight;
        text-align:  center;
        margin:      0 0 27px 0;
    }
}
