@import '../../styles/fonts';
@import '../../styles/colors';
@import '../../styles/zIndexes';

.productDataValueLabelIconWrapper
{
    display: inline-block;
    margin:  0 10px 0 0;

    svg
    {
        position: relative;
        bottom:   -3px;
    }
}
