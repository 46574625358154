@import '../../styles/fonts';
@import '../../styles/colors';
@import '../../styles/variables';

.buttonBarButton
{
    height:          70px;
    border-left:     1px solid $colorGrayLighter3;
    display:         flex;
    flex:            1;
    align-items:     center;
    justify-content: center;
    cursor:          pointer;
    user-select:     none;

    &:first-child
    {
        border-left: none;
    }

    a
    {
        text-decoration: none;
    }

    &:hover
    {
        .buttonBarButtonText
        {
            color: $colorPrimary;
        }
    }
}

.buttonBarButtonClickWrapper
{

}

.buttonBarButtonInner
{
    display:         flex;
    align-items:     center;
    justify-content: center;
    flex-direction:  column;
}

.buttonBarButtonInnerIconWrapper
{
    width:  32px;
    height: 32px;
    margin: 0 0 10px 0;

    svg
    {
        *
        {
            fill: $colorPrimary;
        }
    }
}

.buttonBarButtonText
{
    color:          $colorGrayLighter;
    font-family:    $mainFont;
    font-size:      13px;
    text-transform: uppercase;
    transition:     color $defaultTransitionSpeed linear;
}
