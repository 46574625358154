@import '../../styles/fonts';
@import '../../styles/colors';

.productTitle
{
    color:       $colorGrayLight;
    font-size:   18px;
    font-family: $mainFont;
    padding:     0 24px 0 0;
}

.productTitleCustomName
{
    color:  $colorPrimary;
    margin: 0 0 2px 0 !important;
}

.productTitleSubTitle
{
    font-size:   14px;
    font-weight: 300;
    color:       $colorGrayLight;
    font-family: $mainFont;
    margin:      2px 0 0 0;
}
