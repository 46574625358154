@import '../../styles/fonts';
@import '../../styles/colors';
@import '../../styles/variables';
@import '../../styles/zIndexes';

.subSlotListSlot
{
    border-bottom: 1px solid $colorGrayLighter3;
    position:      relative;
    transition:    background-color $defaultTransitionSpeed linear;
    user-select:   none;

    &:last-child
    {
        border-bottom: 0;
    }
}

.subSlotListSlotWrapper
{
    border:     1px solid $colorTransparent;
    transition: border-color $defaultTransitionSpeed linear;
    position:   relative;
}

.subSlotListSlotEven
{
    background:        $colorWhite;
    border-left-color: $colorWhite;
}

.subSlotListSlotOdd
{
    background:        $colorGrayLighter2;
    border-left-color: $colorGrayLighter2;
}

.productSlotDeleteButton
{
    background-size: contain;
    color:           $colorGrayLight;
    font-size:       11px;
    font-family:     $mainFont;
    display:         block;
    position:        absolute;
    top:             8px;
    right:           8px;
    width:           14px;
    overflow:        hidden;
    z-index:         $zIndexSlotDeleteButton;
    cursor:          pointer;

    svg
    {
        *
        {
            fill: $colorGrayLight;
        }
    }

    &:hover
    {
        svg
        {
            *
            {
                fill: $colorGray;
            }
        }
    }

    &:active
    {
        svg
        {
            *
            {
                fill: $colorPrimary;
            }
        }
    }
}

.slotSfpListSlotContent
{
    display:        flex;
    flex-direction: row;
    padding:        6px;
    border-left:    2px solid $colorTransparent;
}

.slotSfpListSlotNumber
{
    color:       $colorGrayLight;
    font-size:   14px;
    font-family: $mainFont;
    min-width:   26px;
    transition:  color $defaultTransitionSpeed linear;
    white-space: pre;
}

.slotSfpListSlotTitle
{
    color:         $colorGrayLight;
    font-size:     14px;
    font-family:   $mainFont;
    font-weight:   600;
    width:         100%;
    white-space:   nowrap;
    height:        20px;
    overflow:      hidden;
    text-overflow: ellipsis;
    transition:    color $defaultTransitionSpeed linear;
}

.slotSfpListSlotTitleWithCustomText
{
    color: $colorPrimary;
}

.subSlotListSlotTitleEmpty
{
    color:       $colorGrayLighter;
    font-size:   14px;
    font-family: $mainFont;
}

.slotSfpListSlotTitleSub
{
    font-family: $mainFont;
    font-size:   14px;
    font-weight: 300;
    color:       $colorGrayLight;
    transition:  color $defaultTransitionSpeed linear;
}

.subSlotListSlotTitleWrapper
{
    padding:        0;
    display:        flex;
    flex-direction: column;
    box-sizing:     border-box;
    width:          100%;
}

.slotSfpListSlotTitleWrapperHasTitle
{
    padding-right: 50px;
}
