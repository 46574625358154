@import '../../styles/fonts';
@import '../../styles/colors';

.warningListItem
{
    border-bottom-style: solid;
    border-bottom-width: 1px;
    padding:             11px 11px 11px 30px;
    font-size:           14px;
    font-family:         $mainFont;
    position:            relative;

    &:last-child
    {
        border-bottom: 0;
    }
}

.warningListItemError
{

}

.warningListItemGray
{
    border-bottom-color: $colorGrayLighter3;

    .warningListItemIconWrapper
    {
        svg
        {
            *
            {
                fill: $colorGrayLighter;
            }
        }
    }

    &.warningListItemError
    {
        svg
        {
            *
            {
                fill: $colorPrimary;
            }
        }
    }
}

.warningListItemInformation
{

}

.warningListItemOrange
{
    border-bottom-color: $colorWhite;
    color:               $colorWhite;

    .warningListItemIconWrapper
    {
        svg
        {
            *
            {
                fill: $colorWhite;
            }
        }
    }
}

.warningListItemIconWrapper
{
    position: absolute;
    top:      10px;
    left:     0;
    width:    20px;
    height:   20px;
}
