@import '../../styles/fonts';
@import '../../styles/colors';
@import '../../styles/variables';
@import '../../styles/zIndexes';

.zoomAndPanControl
{
    height:      100%;
    cursor:      move;
    width:       100%;
    position:    relative;
    overflow:    hidden;
    background:  $colorGrayLighter2;
    user-select: none;
}

.zoomAndPanControlWrapper
{
    height:   calc(100% - 38px);
    width:    100%;
    position: relative;

    &.noStatusBar
    {
        height: 100%;
    }
}

.zoomAndPanControlHint
{
    color:       $colorGrayLight;
    opacity:     0.5;
    position:    absolute;
    bottom:      10px;
    left:        10px;
    font-size:   12px;
    cursor:      help;
    font-family: $mainFont;
    z-index:     $zIndexZoomAndPanControlButtons;
    transition:  opacity $defaultTransitionSpeed linear;

    &:hover
    {
        opacity: 1;
    }
}

.zoomAndPanControlButton
{
    cursor:          pointer;
    height:          30px;
    background:      $colorGrayLighter3;
    align-items:     center;
    display:         block;
    justify-content: center;
    width:           38px;

    &:hover
    {
        svg
        {
            *
            {
                fill: $colorGray;
            }
        }
    }

    &:active
    {
        svg
        {
            *
            {
                fill: $colorPrimary;
            }
        }
    }
}

.zoomAndPanControlButtonZoomIn
{
    overflow:      hidden;
    border-radius: 0 0 3px 3px;
}

.zoomAndPanControlButtonZoomOut
{
    overflow:      hidden;
    border-radius: 3px 3px 0 0;
}

.zoomAndPanControlButtonDetails
{
    overflow:      hidden;
    border-radius: 3px;
}

.zoomAndPanControlButtons
{
    position:        absolute;
    top:             10px;
    right:           10px;
    z-index:         $zIndexZoomAndPanControlButtons;
    width:           38px;
    border-radius:   3px;
    height:          61px;
    background:      $colorGrayLighter2;
    display:         flex;
    align-items:     center;
    flex-direction:  column;
    justify-content: space-around;
}

.zoomAndPanControlDetailButton
{
    position: absolute;
    bottom:   10px;
    right:    10px;
    z-index:  $zIndexZoomAndPanControlButtons;
}

.zoomAndPanControlZoomLevel
{
    text-align:  center;
    font-size:   11px;
    font-family: $mainFont;
    position:    absolute;
    bottom:      -18px;
    left:        0;
    width:       38px;
    color:       $colorGrayLighter;
}
