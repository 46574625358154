@import '../../styles/fonts';
@import '../../styles/colors';

.unsavedProjectBanner
{
    font-family:     $mainFont;
    padding:         10px 20px;
    background:      $colorBannerBackground;
    display:         flex;
    align-items:     center;
    justify-content: space-between;

    .textButtonContainer
    {
        display:     flex;
        align-items: center;

        .text
        {
            margin-right: 10px;
            color:        $colorBannerText;
        }
    }

    > span
    {
        min-width: unset;
    }
}
