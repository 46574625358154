@import '../../styles/fonts';
@import '../../styles/colors';
@import '../../styles/zIndexes';

.dragLayer
{
    position:       fixed;
    pointer-events: none;
    z-index:        $zIndexDragLayer;
    left:           0;
    top:            0;
    width:          100%;
    height:         100%;
}
