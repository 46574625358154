@import '../../styles/fonts';
@import '../../styles/colors';

.leftPaneRouter
{
    overflow-x:     hidden;
    height:         100%;
    width:          100%;
    display:        flex;
    flex-direction: column;

    &::-webkit-scrollbar
    {
        width: 0 !important;
    }
}
