@import '../../styles/fonts';
@import '../../styles/colors';

.projectList
{
    padding:        30px 100px 0 100px;
    background:     $colorGrayLighter2;
    flex:           1;
    display:        flex;
    flex-direction: column;
    list-style:     none;
}

.projectListInner
{
    flex:          1;
    background:    $colorWhite;
    border:        1px solid $colorGrayLighter3;
    border-radius: 4px;
    list-style:    none;
}

.projectListTitle
{
    color:       $colorGrayLight;
    font-size:   24px;
    font-family: $mainFont;
    margin:      0 0 24px 0;
    position:    relative;
}

.projectListTooltipWrapper
{
    position: absolute;
    right:    0;
    top:      6px;
}

.projectListTooltipWrapperText
{
    font-size: 12px;
    float:     left;
    padding:   0 10px 0 0;
}
