@import '../../styles/fonts';
@import '../../styles/colors';
@import '../../styles/zIndexes';

$dropDownWidth: 180px;

.dropDown
{
    border-top: 3px solid $colorPrimary;
    width:      $dropDownWidth;
    background: $colorWhite;
    box-shadow: 0 5px 10px 0 #0000000c;
    position:   absolute;
    top:        70px;
    z-index:    $zIndexDropDown;
    padding:    2px 2px 0 2px;
}

.dropDownBlack
{
    border-color: $colorGray;
}

.dropDownOrange
{
    border-color: $colorPrimary;
}

.dropDownCenter
{
    // The 28px here center the arrow
    // Don't know why this is required
    margin: 0 0 0 calc(-1 * $dropDownWidth/2) + 28px;
}

.dropDownLeft
{
    left: 0;
}

.dropDownRight
{
    right: 0;
}

.dropDownArrow
{
    width:         0;
    height:        0;
    border-left:   10px solid transparent;
    border-right:  10px solid transparent;
    border-bottom: 10px solid $colorPrimary;
    position:      absolute;
    top:           -12px;
}

.dropDownArrowBlack
{
    border-bottom-color: $colorGray;
}

.dropDownArrowOrange
{
    border-bottom-color: $colorPrimary;
}

.dropDownArrowCenter
{
    left:   50%;
    margin: 0 0 0 -5px;
}

.dropDownArrowLeft
{
    left: 10px;
}

.dropDownArrowRight
{
    right: 10px;
}
