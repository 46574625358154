@import '../../styles/fonts';
@import '../../styles/colors';

.audioLabel
{
    display: flex;
    cursor:  help;
}

.audioLabelLeft
{
    justify-content: flex-start;
}

.audioLabelRight
{
    justify-content: flex-end;
}

.audioText
{
    padding:       0 0 0 10px;
    text-overflow: ellipsis;
    overflow:      hidden;

    &.withoutIcon
    {
        padding: 0;
    }
}

.audioLabelIconWrapper
{
    display: inline-block;
    width:   20px;
}
