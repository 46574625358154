@import '../../styles/fonts';
@import '../../styles/colors';
@import '../../styles/variables';

.leftPaneSingleOrderItem
{
    height:            33px;
    position:          relative;
    display:           flex;
    align-items:       center;
    box-shadow:        0 1px 2px 0 $colorGrayLighter;
    border-bottom:     1px solid $colorGrayLighter3;
    padding:           0 $singleOrderItemSidePadding 0 ($singleOrderItemSidePadding - 2);
    transition:        background-color $defaultTransitionSpeed linear, border-color $defaultTransitionSpeed linear;
    border-left-width: 2px;
    border-left-style: solid;
}

.leftPaneSingleOrderItemHasProduct
{
    cursor: pointer;

    .leftPaneSingleOrderItemTitle
    {
        color: $colorGrayLight;
    }

    &:hover
    {
        background-color: $colorPrimaryLighter2;
        border-color:     $colorPrimaryLighter2;
    }
}

.leftPaneSingleOrderItemSelected
{
    background-color:  $colorPrimaryLighter2 !important;
    border-left-color: $colorPrimary !important;
}

.leftPaneSingleOrderItemTitle
{
    color:       $colorGrayLighter;
    font-size:   14px;
    font-family: $mainFont;
    width:       77%;
}

.leftPaneSingleOrderItemDragging
{

}

.leftPaneSingleOrderItemEven
{
    background-color:  $colorWhite;
    border-left-color: $colorWhite;
}

.leftPaneSingleOrderItemNoHover
{

}

.leftPaneSingleOrderItemOdd
{
    background-color:  $colorGrayLighter2;
    border-left-color: $colorGrayLighter2;
}

.leftPaneSingleOrderItemSingleOrderNumber
{
    color:       $colorGrayLight;
    font-size:   14px;
    font-family: $mainFont;
    min-width:   26px;
    transition:  color $defaultTransitionSpeed linear;
}

.leftPaneSingleOrderItemControls
{
    position:    absolute;
    top:         0;
    right:       $singleOrderItemSidePadding;
    height:      100%;
    display:     flex;
    align-items: center;

}

.leftPaneSingleOrderItemAmountInput
{
    width:         30px;
    height:        21px;
    border-radius: 4px;
    border:        1px solid $colorGrayLight3;
    text-align:    center;
    font-family:   $mainFont;
    font-size:     14px;
    color:         $colorGrayLighter;
    transition:    border-color $defaultTransitionSpeed linear;
    outline:       none;
    margin:        0 4px;

    &[type="number"]::-webkit-outer-spin-button,
    &[type="number"]::-webkit-inner-spin-button
    {
        -webkit-appearance: none;
        margin:             0;
    }

    &[type="number"]
    {
        -moz-appearance: textfield;
    }

    &:hover,
    &:focus
    {
        border-color: $colorPrimary;
        color:        $colorGrayLight;
    }

    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px)
    {
        pointer-events: none;
    }
}

.leftPaneSingleOrderItemControlsDecreaseAmountButtonWrapper,
.leftPaneSingleOrderItemControlsIncreaseAmountButtonWrapper,
.leftPaneSingleOrderItemControlsDeleteButtonWrapper
{
    cursor: pointer;

    svg
    {
        *
        {
            fill: $colorGrayLighter;
        }
    }

    &:hover
    {
        svg
        {
            *
            {
                fill: $colorGrayLight3;
            }
        }
    }

    &:active
    {
        svg
        {
            *
            {
                fill: $colorPrimary;
            }
        }
    }
}

.leftPaneSingleOrderItemControlsDecreaseAmountButtonWrapper,
.leftPaneSingleOrderItemControlsIncreaseAmountButtonWrapper
{
    width:   9px;
    height:  9px;
    padding: 5px;

    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px)
    {
        padding: 13px 5px;
    }
}

.leftPaneSingleOrderItemControlsDecreaseAmountButtonWrapper
{

}

.leftPaneSingleOrderItemControlsIncreaseAmountButtonWrapper
{

}

.leftPaneSingleOrderItemControlsDeleteButtonWrapper
{
    width:  24px;
    height: 24px;
    margin: 0 0 0 6px;
}
