@import '../../styles/fonts';
@import '../../styles/colors';
@import '../../styles/fonts';
@import '../../styles/colors';
@import '../../styles/variables';

.productDetailItem
{
    padding: $leftPaneSidePadding 0;
}

.productDetailItemDeleteButton,
.productDetailItemDuplicateButton
{
    background-size: contain;
    color:           $colorGrayLight;
    font-size:       11px;
    font-family:     $mainFont;
    cursor:          pointer;
    display:         block;
    position:        absolute;
    overflow:        hidden;

    svg
    {
        *
        {
            fill: $colorGrayLight !important;
        }
    }

    &:hover
    {
        svg
        {
            *
            {
                fill: $colorGray;
            }
        }
    }

    &:active
    {
        svg
        {
            *
            {
                fill: $colorPrimary;
            }
        }
    }
}

.productDetailItemDeleteButton
{
    bottom: 0;
    right:  -10px;
    width:  14px;
    height: 19px;
}

.productDetailItemDuplicateButton
{
    bottom: 0;
    right:  20px;
    width:  16px;
    height: 18px;
}

.productDetailItemDetails
{
    position:    relative;
    padding:     10px 20px 10px 18px;
    background:  $colorGrayLighter2;
    margin:      0 $leftPaneSidePaddingSmall 20px $leftPaneSidePaddingSmall;
    box-shadow:  0 1px 2px 0 $colorGrayLighter;
    cursor:      pointer;
    transition:  background-color $defaultTransitionSpeed linear,
                 border-color $defaultTransitionSpeed linear;
    border-left: 2px solid $colorGrayLighter2;

    &.productDetailItemDetailsSelected,
    &:hover
    {
        background:   $colorPrimaryLighter2;
        border-color: $colorPrimaryLighter2;

        &.productDetailItemDetailsSelected
        {
            border-left-color: $colorPrimary;
        }
    }
}

.productDetailItemInformationActions
{
    position: absolute;
    top:      0;
    right:    0;

}

.productDetailItemInformationDropZoneExtraStyle
{
    left: -2px;
}

.productDetailItemInformationWrapper
{
    padding:         4px 0 0 0;
    position:        relative;
    display:         flex;
    flex-direction:  row;
    flex-wrap:       wrap;
    justify-content: space-between;
    margin:          0;
}

.productDetailItemSlots
{
    padding: 0 $leftPaneSidePaddingSmall 20px $leftPaneSidePaddingSmall;
}
