@import '../../styles/fonts';
@import '../../styles/colors';
@import '../../styles/variables';

.fileDropZone
{

}

.fileDropZoneOuter
{
    border-radius: 4px;
    border:        1px solid $colorGrayLighter3;
    background:    $colorGrayLighter2;
    padding:       6px;
    transition:    background-color $defaultTransitionSpeed linear;

    &:hover
    {
        background: $colorGrayLighter3;

        .fileDropZoneInner
        {
            border-color: $colorGrayLighter;

            .fileDropZoneInnerIcon
            {
                svg
                {
                    *
                    {
                        fill: $colorGrayLighter;
                    }
                }
            }
        }
    }
}

.fileDropZoneInner
{
    border-radius:   4px;
    border:          2px dashed $colorGrayLighter3;
    padding:         40px 40px;
    text-align:      center;
    display:         flex;
    justify-content: center;
    flex-direction:  column;
    align-items:     center;
    outline:         none;
    transition:      border-color $defaultTransitionSpeed linear;
    cursor:          pointer;
}

.fileDropZoneInnerIcon
{
    margin:  0 0 10px 0;
    display: block;

    svg
    {
        *
        {
            fill: $colorGrayLighter3;
        }
    }
}

.fileDropZoneInnerText
{
    color:       $colorGrayLighter;
    max-width:   100%;
    font-size:   14px;
    font-family: $mainFont;
}

.fileDropZoneUploadedFilePreviewWrapper
{
    margin: 20px 0 0 0;
}
