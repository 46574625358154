@import '../../styles/fonts';
@import '../../styles/colors';

.buttonBar
{
    padding:         32px;
    background:      $colorWhite;
    display:         flex;
    align-items:     center;
    justify-content: space-around;
}
