@import '../../styles/fonts';
@import '../../styles/colors';

.videoConnectorLabel
{
    display: flex;
    cursor:  help;
}

.videoConnectorLabelLeft
{
    justify-content: flex-start;
}

.videoConnectorLabelRight
{
    justify-content: flex-end;
}

.audioText
{
    padding: 0 0 0 10px;

    &.withoutIcon
    {
        padding: 0;
    }
}

.videoConnectorLabelIconWrapper
{
    display: inline-block;
    width:   20px;
}
