@import '../../styles/fonts';
@import '../../styles/colors';
@import '../../styles/variables';
@import '../../styles/zIndexes';

.standAloneDropZone
{
    height:        162px;
    border-radius: 4px;
    background:    $colorGrayLighter2;
    border:        1px solid $colorGrayLighter3;
    padding:       6px;
    transition:    background-color $defaultTransitionSpeed linear;
    box-sizing:    border-box;
}

.standAloneDropZoneHover
{
    background: $colorGrayLighter3;
}

.standAloneDropZoneOverlay
{
    position:       absolute;
    top:            0;
    left:           0;
    height:         100%;
    width:          100%;
    z-index:        $zIndexStandAloneDropZone;
    opacity:        0;
    transition:     opacity $defaultTransitionSpeed linear;
    pointer-events: none;
}

.standAloneDropZoneOverlayCanDrop
{
    opacity:        1;
    pointer-events: all;
}

.standAloneDropZoneInner
{
    box-sizing:      border-box;
    height:          100%;
    border-radius:   4px;
    border:          2px dashed $colorGrayLighter3;
    display:         flex;
    align-items:     center;
    justify-content: center;
    flex-direction:  column;
    transition:      border-color $defaultTransitionSpeed linear;
}

.standAloneDropZoneInnerHover
{
    border-color: $colorGrayLighter;
}

.standAloneDropZoneIcon
{
    width:  50px;
    height: 50px;
    margin: 0 0 16px 0;

    svg
    {
        *
        {
            fill: $colorGrayLighter;
        }
    }
}

.standAloneDropZoneText
{
    box-sizing:  border-box;
    text-align:  center;
    font-family: $mainFont;
    font-size:   14px;
    line-height: 1.29;
    color:       $colorGrayLighter;
    padding:     0 15px;
    max-width:   100%;
    transition:  color $defaultTransitionSpeed linear;
}

.standAloneDropZoneTextHover
{
    color: $colorGrayLighter;
}
