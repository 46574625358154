@import '../../styles/fonts';
@import '../../styles/colors';

.versionNumberWrapper
{
    font-family: $mainFont;
    font-size:   14px;
    color:       $colorGray;
}

.versionNumber
{
    font-weight: bold;
}
