$errorBoxPadding:              15px;
$headerHeight:                 70px;
$headerStatusBarPaddingHeight: 70px;
$headerSidePadding:            15px;
$leftPaneSidePadding:          30px;
$leftPaneSidePaddingSmall:     calc($leftPaneSidePadding / 2);
$overlaySidePadding:           27px;
$productItemSidePadding:       16px;
$singleOrderItemSidePadding:   9px;
$defaultTransitionSpeed:       0.05s;

@import "customImages";

:export
{
    defaultTransitionSpeed:       defaultTransitionSpeed;
    headerHeight:                 $headerHeight;
    headerStatusBarPaddingHeight: $headerStatusBarPaddingHeight;
}
