@import '../../styles/fonts';
@import '../../styles/colors';
@import '../../styles/zIndexes';

.productPreviewImageWrapper
{
    position:       absolute;
    z-index:        $zIndexProductPreview;
    pointer-events: none;
}

.productPreviewImageWrapperTablet
{
    position:         absolute;
    z-index:          $zIndexProductPreview;
    background-color: $colorBlack80;
    width:            100%;
    height:           100%;
    top:              0;
    left:             0;
    display:          flex;
    align-items:      center;
    justify-content:  center;
}
