@import '../../styles/fonts';
@import '../../styles/colors';
@import '../../styles/variables';

.productWarningList
{
    margin:     0 0 20px 0;
    border-top: 1px solid $colorGrayLighter3;

    h3
    {
        font-family:    $mainFont;
        font-size:      13px;
        color:          $colorGrayLighter;
        text-transform: uppercase;
        margin:         0 0 4px 0;

        span
        {
            color:   $colorGrayLight;
            padding: 0 0 0 7px;

            span
            {
                display: none;
            }
        }
    }
}

.productWarningListWrapper
{
    border-bottom: 1px solid $colorGrayLighter3;
    padding:       0 $leftPaneSidePaddingSmall;
}

.productWarningListWrapperInner
{
    padding: 0 0 $leftPaneSidePaddingSmall 0;
}

.productWarningListItem
{
    margin: 0 0 10px 0;

    &:last-child
    {
        margin-bottom: 0;
    }
}

.productWarningListToggleControl
{
    position:        relative;
    height:          70px;
    display:         block;
    text-decoration: none;
    text-transform:  uppercase;
    font-weight:     600;
    padding:         0 0 0 10px;
    line-height:     73px;
    font-size:       13px;
    cursor:          pointer;
}

.productWarningListToggleControlIcon
{
    display:    inline-block;
    width:      12px;
    height:     14px;
    transition: transform $defaultTransitionSpeed linear;
    margin:     0 10px 0 0;
}

.productWarningListToggleControlIconOpen
{
    transform: rotate(180deg);
}

.productWarningListToggleControlText
{
    color:       $colorGrayLighter;
    font-family: $mainFont;
    font-size:   13px;
    user-select: none;
    margin:      1px 0 0 0;
}

.productWarningListToggleControlBadge
{
    background:    $colorPrimary;
    height:        20px;
    text-align:    center;
    min-width:     8px;
    position:      absolute;
    color:         $colorWhite;
    font-size:     13px;
    right:         10px;
    border-radius: 10px;
    font-family:   $mainFont;
    padding:       0 6px;
    line-height:   19px;
    top:           50%;
    margin-top:    -10px;
}
