@import '../../styles/fonts';
@import '../../styles/colors';
@import '../../styles/zIndexes';

.screenToSmallOverlay
{
    position:       absolute;
    top:            0;
    left:           0;
    width:          100%;
    height:         100%;
    flex-direction: column;
    z-index:        $zIndexScreenToSmallOverlay;
    background:     $colorWhite;
    display:        flex;
    min-width:      320px;

    @media only screen and (min-width: 1024px)
    {
        display: none;
    }
}

.screenToSmallOverlayContent
{
    flex:            1;
    background:      $colorWhite;
    display:         flex;
    align-items:     center;
    justify-content: center;
    text-align:      center;
    flex-direction:  column;
    color:           $colorGrayLight;
    font-family:     $mainFont;
    padding:         50px;

    h2
    {
        font-size:   28px;
        font-weight: 600;
        margin:      0 0 30px 0;
    }

    p
    {
        font-size: 18px;
    }
}
