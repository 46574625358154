@import '../../styles/fonts';
@import '../../styles/colors';

.serialLabel
{
    display: flex;
    cursor:  help;
}

.serialLabelLeft
{
    justify-content: flex-start;
}

.serialLabelRight
{
    justify-content: flex-end;
}

.serialText
{
    padding:       0 0 0 10px;
    text-overflow: ellipsis;
    overflow:      hidden;
}

.serialLabelIconWrapper
{
    display: inline-block;
    width:   20px;
}
