@import '../../styles/fonts';
@import '../../styles/colors';

.usbLabel
{
    display: inline-flex;
    cursor:  help;
}

.usbLabelWrapper
{
    display: flex;
}

.usbLabelLeft
{
    justify-content: flex-start;
}

.usbLabelRight
{
    justify-content: flex-end;
}

.usbText
{

}

.usbLabelIconWrapper
{
    display: inline-block;
    width:   20px;
    padding: 0 10px 0 0;
}
