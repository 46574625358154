@import '../../styles/fonts';
@import '../../styles/colors';
@import '../../styles/zIndexes';

.headerButton
{
    display: inline-block;
    cursor:  pointer;
    z-index: $zIndexHeaderButton;

    svg
    {
        *
        {
            fill: $colorHeaderButtonIcon !important;
        }
    }

    &:hover
    {
        svg
        {
            *
            {
                fill: $colorWhite !important;
            }
        }
    }

    &:active
    {
        svg
        {
            *
            {
                fill: $colorPrimary !important;
            }
        }
    }

    a
    {
        text-decoration: none;
    }
}

.headerButtonMarginLeft
{
    margin-left: 10px;
}

.headerButtonDisabled
{
    cursor:           not-allowed;
    background-color: $colorGray;

    svg,
    &:hover svg,
    &:active svg
    {
        *
        {
            fill: $colorGrayLight2 !important;
        }
    }
}

.headerButtonClickWrapper
{
    height: 100%;
    width:  100%;
}

.headerButtonInner
{
    height:          100%;
    width:           100%;
    display:         flex;
    flex-direction:  column;
    justify-content: center;
    align-items:     center;
}

.headerButtonIconWrapper
{
    height:              28px;
    width:               35px;
    background-color:    $colorHeaderButtonBackground;
    border:              1px solid $colorHeaderButtonBorder;
    background-position: center center;
    background-origin:   content-box;
    background-size:     contain;
    background-repeat:   no-repeat;
    box-sizing:          border-box;
    padding:             2px;
    border-radius:       3px;
}

.headerButtonText
{
    min-width:   70px;
    font-size:   11px;
    color:       $colorHeaderButtonText;
    display:     block;
    text-align:  center;
    bottom:      -16px;
    user-select: none;
    font-family: $mainFont;
    white-space: nowrap;
}

.headerButtonShortText
{
    display: none;
}

@media only screen and (max-device-width: 992px)
{
    .headerButtonText
    {
        display: none;
    }

    .headerButtonShortText
    {
        display: block;
    }
}

@media only screen and (max-device-width: 576px)
{
    .hideXs
    {
        display: none;
    }
}

@media only screen and (min-device-width: 576px) and (max-device-width: 768px)
{
    .hideSm
    {
        display: none;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 992px)
{
    .hideMd
    {
        display: none;
    }
}

@media only screen and (min-device-width: 992px) and (max-device-width: 1200px)
{
    .hideLg
    {
        display: none;
    }
}

@media only screen and (min-device-width: 1200px)
{
    .hideXl
    {
        display: none;
    }
}
